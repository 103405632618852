import React from "react";
import { HeroSection } from "./HeroSection/HeroSection";
import "./Home.css";
import { HowItWorks } from "./HowItWorks/HowItWorks";
import { StepDisplay } from "./StepDisplay/StepDisplay";
import { StreamLine } from "./StreamLine/StreamLine";
import { Tryons } from "./Tryons/Tryons";
import Popup from "./popup";

export const Home = () => {
  return (
    <div className="">
      <HeroSection />
      <HowItWorks />
      <StepDisplay />
      <Tryons />
      <StreamLine />
      <Popup />
    </div>
  );
};
