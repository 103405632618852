import React, { useState, useEffect } from "react";
import "./ResetPassword.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ResetPassword = () => {
  const [forgotpassword, setForgotpassword] = useState("");
  const [msgs, setMsgs] = useState("");
  const [wrong, setWrong] = useState("");
  const navigate = useNavigate("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const reset = async (e) => {
    setForgotpassword("");
    e.preventDefault();
    var data = { email: forgotpassword };
    const res = axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/v1/password/forgot`, data)
      .then((res) => {
        if (res?.data.status) {
          setMsgs((type) => {
            return {
              ...type,
              msg: "Please check your email to reset your password",
            };
          });
        }
      }, [])
      .catch((error) => {
        setWrong((err) => {
          return {
            msg: "Email not found",
          };
        });
      });
  };
  const log = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="heading-getstarted"></div>
      <div className="login-form">
        <h1 className="auth_title text-left-word">Password Reset</h1>
        <form>
          <div className="form-group">
            <input
              type="email"
              className="form-control log"
              name="email"
              placeholder="Email Address"
              value={forgotpassword}
              onChange={(txt) => {
                setForgotpassword(txt.target.value);
                setWrong("");
                setMsgs("");
              }}
              required
            />
          </div>

          <div className="form-group">
            <button
              type="button"
              className="btn btn-lg-primary btn-lg btn-block"
              onClick={reset}
              disabled={!forgotpassword}
            >
              Reset Password
            </button>
          </div>
          {msgs?.msg == null ? null : (
            <span className="reset-msg">{msgs?.msg}</span>
          )}
          {wrong?.msg == null ? null : (
            <span className="wrong-msg">{wrong?.msg}</span>
          )}
        </form>
        <div className=" reset-login">
          <p>
            Go back to <u onClick={log}>Log in</u> screen
          </p>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
