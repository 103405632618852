import React, { useState, useEffect } from "react";
import LoginHero from "./LoginHero/LoginHero";
import ShapeBasics from "./ShapeBasics/ShapeBasics";
import Catalogs from "./Catalogs/Catalogs";
import { Blog } from "../Confirmation/Blog/Blog";
import { createClient } from "contentful";
import { useNavigate } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import VideoCrousel from "../VideoCrousel/";
import moment from "moment";
import Modal from "react-modal";

Modal.setAppElement("#root");

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const LoginSuccess = () => {
  const [subscription, setSubscription] = useState();
  const [subinfo, setSubinfo] = useState(true);
  const [payModal, setPayModal] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const userData = localStorage.getItem("color");
  const navigate = useNavigate();

  const account = () => {
    navigate("/settings");
  };

  let profileTok = localStorage.getItem("tokenn");
  const profileData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/v1/users/get_profile`, {
        headers: {
          Authorization: `Bearer ${profileTok}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setSubscription(response?.data?.user);
      });
  };

  useEffect(() => {
    fetchData();
    profileData();
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    const entry = await client.getEntries({
      content_type: "shopYourCatalogs",
      order: "sys.id",
    });
    if (entry?.items?.length > 0) {
      const findData = entry.items.filter(
        (x) => x?.fields?.bodyShape?.toLowerCase() === userData.toLowerCase()
      );
      let vids = [];
      if (findData?.length > 0) {
        let vPayload;
        for (let i = 0; i < findData?.length; i++) {
          const element = findData[i];
          if (element) {
            vPayload = {
              url: element?.fields?.videoUrl,
              title: element?.fields?.videoTitle,
              thumbnail: element?.fields?.videoImage?.fields?.file?.url,
              color: element?.fields?.bodyShape,
              id:element?.sys?.id
            };

            vids.push(vPayload);
          }
        }
        setVideoData(vids);
      }
    }
  }

  const subscription_status = subscription?.subscriptions[0].subscription_status

  return (
    <div className="login_sucess_wrapper nav-gap">
      {subscription_status === "expired" ||
       (subscription_status === "cancelled" &&
        moment.utc(subscription?.subscriptions[0].expire_at) < moment.utc()) ? (
        <Modal
          className="cancel-modal"
          isOpen={subinfo}
          onRequestClose={() => setSubinfo(false)}
        >
          <>
            <div className="cancel-plansss">
              <h4>We miss you already!</h4>
              <p>
                Your plan has expired. You can add payment on your account page
                to continue receiving your ShapeShopp goodies.
              </p>
              <button className="btn btn edit-info" onClick={account}>
                My Account
              </button>
            </div>
          </>
        </Modal>
      ) : (
        <>
          {" "}
          <LoginHero />

          <ShapeBasics />
          <div className="howitworks-container streamline-containers ">
            <div className="container">
              <div className="how-it-works-icon font-lg-32 dash-vids">
                <div className="play-icon"></div>
                <span>Your </span> 5 Days of Style Videos
              </div>
            </div>
            <div className="style-videos">
              <div className="video-wrapper" id="videos">
                <div className="container">
                  <VideoCrousel videos={videoData}/>
                  <p className="video-line">
                    *You can find all items shown in the videos &nbsp;
                    <a
                      href="https://hueandstripe.com/catalog/320H&SBpLJ"
                      target="blank"
                    >
                      here*
                    </a>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks-container streamline-containers">
            <div className="container">
              <div className="how-it-works-icon font-lg-32">
                <div className="play-icon"></div>
                <span>Shop </span>your catalogs
              </div>
              <h4 className="shop-login">For every season and all occasions</h4>
            </div>
            <div className="container-fluid">
              <div className="catalog-images"
              id="catalogs">
                <div className="row cat">
                  <Catalogs />
                </div>
              </div>
            </div>
          </div>
          <Blog />
          <div className="howitworks-container streamline-containers">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="howitworks-content newsletter-content ">
                    <h2 className="font-lg-24">
                      Stay connected and join our community
                    </h2>
                    <p className="pt-2">
                      You're in good company. Our community of stylists and
                      like-minded fashionistas, like yourself, are here to help.
                      Join our private ShapeShopp™ Confidential Community and
                      get tailored info sent directly to you!
                    </p>
                    <div className="font-lg-24 join">
                      {" "}
                      <u>
                        <a
                          href="https://www.facebook.com/groups/524782371339690"
                          target="blank"
                          id="Join now_facebook"
                        >
                          Join Now
                        </a>
                      </u>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <img
                    className="fb-cover-img"
                    src="../../../../images/Facebook_Cover__1640___856_px_.jpg"
                    height="300px"
                    width="100%"
                    alt=""
                  />
                </div>
              </div>
              <div className="color-bar bar_color">
                <div className="multi-color-section"></div>
              </div>
            </div>{" "}

          </div>
        </>
      )}
      <Modal
        className="cancel-modal"
        isOpen={payModal}
        onRequestClose={() => setPayModal(false)}
      >
        <>
          <div className="cancel-planss">
            <p>
              Your credit card has expired. Please update your payment method to
              continue receiving great content
            </p>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default LoginSuccess;
