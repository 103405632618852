import React, { useState, useEffect } from "react";
import { FadeInSection } from "../../shared/Layout/IntersectionObserver";
import "./HowItWorks.css";
import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

export const HowItWorks = () => {
  const [work, setWork] = useState();
  const [section2, setSection2] = useState();

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "howItWorks",
      order: "sys.id",
    });
    setWork(entry.items);
  }, []);

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "shapeshoppSection2",
      order: "sys.id",
    });
    setSection2(entry.items);
  }, []);

  return (
    <div className="howitworks-container">
      <div className="container">
        {section2?.map((ele, key) => (
          <FadeInSection key={key}>
            <div className="howitworks-content">
              <h2 className="font-lg-32">{ele.fields.text}</h2>
            </div>
          </FadeInSection>
        ))}

        <FadeInSection>
          <div className="color-bar">
            <div className="multi-color-section"></div>
            <br />
            <div
              className="how-it-works-icon step
             font-lg-32"
            >
              <div className="play-icon"></div>
              <span>How</span>it works
            </div>
          </div>
        </FadeInSection>
      </div>
      <div className="container-fluid">
        <FadeInSection>
          <div className="dress-steps how">
            <div className="row how">
              {work?.map((element, key) => (
                <div key={key} className="col-lg-4 col-md-4">
                  <div className="dress-steps-item">
                    <div className="step-label">
                      <p>{element.fields.step}</p>
                    </div>
                    <div className="step-content pt-2">
                      <h4>{element.fields?.style}</h4>

                      <p>{element.fields?.description}</p>
                    </div>
                    <div className="dress-1-img">
                      <img
                        src={element.fields?.styleImage.fields.file.url}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </FadeInSection>
      </div>
    </div>
  );
};
