import React, { useState, useEffect } from "react";
import "./Confirmation-Stay-Connected.css";
import { FadeInSection } from "../../shared/Layout/IntersectionObserver";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

export const ConfirmationStayConnected = (props) => {
  const [data, setData] = useState([]);
  const [catinfo, setCatinfo] = useState("");
  const navigate = useNavigate();

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "daysOfStyleVideos",
      order: "sys.id",
    });
    let image = entry.items.filter((x) => x?.fields?.bodyShape === props.color);
    let catFilter = image?.find(
      (y) => y?.fields?.brandName == "Your Living Cheat Sheet"
    );
    let filtercat = image.filter(
      (z) => z?.fields?.brandName != "Your Living Cheat Sheet"
    );

    setData(filtercat);
    setCatinfo(catFilter);
  }, [""]);

  const dash = () => {
    navigate("/login-successful");
  };

  return (
    <>
      <div className="streamline-container">
        <div className="container sty">
          <FadeInSection>
            <div className="how-it-works-icon font-lg-32">
              <div className="play-icon"></div>
              <span>Style</span>
            </div>
            <h2 className="shape_content">
              {" "}
              5 days of videos will arrive on your{" "}
              <u onClick={dash}>dashboard</u>
            </h2>
            <div className="days-wrapper">
              <div className="row">
                <div className="days-main">
                  <div className="day_headings">
                    <h4 className="font-lg-18 shape_orange_color">DAY 1</h4>
                    <h4 className="font-lg-20">Silhouette</h4>
                  </div>
                  <Link to="/login-successful">
                    <div className="days-img">
                      <img
                        src="/images/Day-1.png"
                        className="img-fluid shape_days"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
                <div className="days-main">
                  <div className="day_headings">
                    <h4 className="font-lg-18 shape_orange_color">DAY 2</h4>
                    <h4 className="font-lg-20">Tops</h4>
                  </div>
                  <Link to="/login-successful">
                    <div className="days-img">
                      <img
                        src="/images/Day-2.png"
                        className="img-fluid shape_days"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
                <div className="days-main">
                  <div className="day_headings">
                    <h4 className="font-lg-18 shape_orange_color">DAY 3</h4>
                    <h4 className="font-lg-20">Pants</h4>
                  </div>
                  <Link to="/login-successful">
                    <div className="days-img">
                      <img
                        src="/images/Day-3.png"
                        className="img-fluid shape_days"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
                <div className="days-main">
                  <div className="day_headings">
                    <h4 className="font-lg-18 shape_orange_color">DAY 4</h4>
                    <h4 className="font-lg-20">Skirts</h4>
                  </div>
                  <Link to="/login-successful">
                    <div className="days-img">
                      <img
                        src="/images/Day-4.png"
                        className="img-fluid shape_days"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
                <div className="days-main">
                  <div className="day_headings">
                    <h4 className="font-lg-18 shape_orange_color">DAY 5</h4>
                    <h4 className="font-lg-20">Dresses</h4>
                  </div>
                  <Link to="/login-successful">
                    <div className="days-img">
                      <img
                        src="/images/Day-5.png"
                        className="img-fluid shape_days"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </FadeInSection>
        </div>
      </div>
      <div className="streamline-container">
        <FadeInSection>
          <div className="container">
            <div className="how-it-works-icon font-lg-42">
              <div className="play-icon"></div>
              <span>Shop</span>
            </div>
            <h2 className="shape_content">
              Visit your <u onClick={dash}>dashboard</u> for your weekly catalogs
            </h2>
          </div>
          <div className="catalogs">
            <div className="col-md-3">
              <div className="catelog-wrapper">
                <a target="blank" href={catinfo?.fields?.url}>
                  <img
                    className="img-fluid"
                    src={catinfo?.fields?.productImage?.fields?.file?.url}
                    alt=""
                  />
                  <h4>{catinfo?.fields?.brandName}</h4>
                  <p>{catinfo?.fields?.productName}</p>
                </a>
              </div>
            </div>
            {data?.slice(0, 1).map((element, key) => (
              <div className="col-md-3" key={key}>
                <div className="catelog-wrapper">
                  <a target="blank" href={element?.fields?.url}>
                    <img
                      className="img-fluid"
                      src={element?.fields?.productImage?.fields?.file?.url}
                      alt=""
                    />
                    <h4>{element?.fields?.brandName}</h4>
                    <p>{element?.fields?.productName}</p>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </FadeInSection>
      </div>

      <div className="container newsletter_section">
        <div className="newsletter">
          <img src="/images/Email_icon.png" alt="" />
          <p>
            We'll drop emails and text messages once a week to help you shop
            your shape and love your closet.
          </p>
        </div>
      </div>
    </>
  );
};
