import React, { useState, useEffect } from "react";
import "./StreamLine.css";
import { Link } from "react-router-dom";
import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

export const StreamLine = () => {
  const [pack, setPack] = useState();
  const [section4, setSection4] = useState();

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "package",
      order: "sys.id",
    });
    setPack(entry.items);

    const entry2 = await client.getEntries({
      content_type: "shapeshoppSection4",
      order: "sys.id",
    });
    setSection4(entry2.items);
  }, []);

  return (
    <div
      className="howitworks-container streamline-container mobileview-width"
      id="how-it-works"
    >
      <div className="container">
        <div className="howitworks-content landingbars">
          {section4?.map((ele, key) => (
            <h2 key={key} className="font-lg-32">
              <div
                dangerouslySetInnerHTML={{ __html: ele?.fields?.text }}
              ></div>
            </h2>
          ))}
          <div className="color-bar baar">
            <div className="multi-color-section"></div>
          </div>
        </div>

        <div className="choose-subscription">
          <div className="how-it-works-icon font-lg-32">
            <div className="play-icon"></div>
            <span>Choose</span>your subscription package
          </div>
        </div>
      </div>

      <div className="container-fluid dress-steps">
        <div className="row">
          {pack?.map((element, key) => (
            <div key={key} className="col-lg-4 col-md-6 mb-3">

              <div
                className={
                  element.fields.packageType === "Smart"
                    ? "dress-steps-item price-item price-item-1"
                    : "" || element.fields.packageType === "Savvy"
                    ? "dress-steps-item price-item price-item-2"
                    : "" || element.fields.packageType === "Custom"
                    ? "dress-steps-item price-item price-item-3"
                    : ""
                }
              >
                {key === 0 && (
                  <span className="left_pricing_tag">
                    Our Most Loved
                  </span>
                )}
                <h3 className="font-lg-32">
                  {element.fields.packageType}
                </h3>

                <p className="font-lg-24">
                  {element.fields.monthly}
                </p>

                <ul className="package-details">
                  {element?.fields?.packagesList?.content.map((ele,key) => (
                    <li key={key}>
                      {ele.content[0].value}
                    </li>
                  ))}
                </ul>

                <div className="dresslink_wrapper">
                  <Link to="/pricing-plans">
                    <div className="learn-more">
                      <button
                        id={`${"learn_more_"}${element.fields.id}`}
                        type="button"
                        className="btn edit-info"
                      >
                        Learn More
                      </button>
                    </div>
                  </Link>
                </div>

              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
