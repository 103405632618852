import React, { useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./components/Home/Home";
import { Shape } from "./components/Shape/Shape";
import { GetStarted } from "./components/Home/GetStarted/GetStarted";
import { Footer } from "./components/shared/Footer/Footer";
import { NavBar } from "./components/shared/NavBar/NavBar";
import { NotFoundPage } from "./components/shared/NotFoundPage/NotFoundPage";
import "bootstrap/dist/css/bootstrap.min.css";
import { Confirmation } from "./components/Confirmation/Confirmation";
import PricingPlan from "./components/Price/pricing-plan-main";
import Login from "./components/Login/Login";
import ResetPassword from "./components/Login/ResetPassword";
import LoginSuccess from "./components/Login/LoginSuccess";
import Privacy from "./components/Privacy/privacy";
import Terms from "./components/Term/term";
import Blogg from "./components/blogg/bloglistening";
import BlogPage from "./components/blogg/bloggpage";
import MyAccount from "./components/my-account/My-Account";
import PrivateRoute from "./components/Routing/PrivateRoute";
import CreatePassword from "./components/Login/CreatePassword";
import Profilestate from "./Context/ProfileState";
import OurStory from "./components/Home/Our Story/OurStory";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function App() {
  return (
    <BrowserRouter>
      <Profilestate>
        <NavBar />
        <div className="wrapper">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/shape" element={<Shape />} />
            <Route path="/get-started" element={<GetStarted />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/term" element={<Terms />} />
            <Route path="/blog" element={<Blogg />} />
            <Route path="/blog/:id" element={<BlogPage />} />
            <Route path="/pricing-plans" element={<PricingPlan />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/create-password" element={<CreatePassword />} />
            <Route path="/our-story" element={<OurStory />} />

            {/* Private Routes */}

            <Route exact path="/" element={<PrivateRoute />}>
              <Route
                path="/confirmation/:color/:background_id"
                element={<Confirmation />}
              />
              <Route path="/login-successful" element={<LoginSuccess />} />
              <Route path="/settings" element={<MyAccount />} />
            </Route>
          </Routes>
        </div>
        <Footer />
      </Profilestate>
    </BrowserRouter>
  );
}

export default App;
