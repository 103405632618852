import React, { useState, useEffect } from "react";
import "./LoginHero.css";
import { FadeInSection } from "../../shared/Layout/IntersectionObserver";
import list from "../../Home/GetStarted/body.json";
import { createClient } from "contentful";
import { Link } from "react-router-dom";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const LoginHero = (props) => {
  const [back, setBack] = useState();
  const [bodylist, setBodylist] = useState();
  const [listdata, setListData] = useState();
  const [merge, setMerge] = useState();
  const [work, setWork] = useState();
  const [firstTimeVisit, setFirstTimeVisit] = useState(false);

  const bodyColor = localStorage.getItem("color");

  useEffect(() => {
    isFirstTimeVisit();
  }, []);

  const isFirstTimeVisit = () => {
    const firstTimeSignUp = localStorage.getItem("firstTimeSignUp");
    if (firstTimeSignUp && firstTimeSignUp == "true") {
      localStorage.setItem("firstTimeSignUp", false);
      setFirstTimeVisit(true);
    }
  };

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "bodyShape",
      order: "sys.id",
    });
    setListData(entry.items);
    let join = [];
    list?.map((da) => {
      entry.items?.map((res) => {
        if (parseInt(res?.fields?.id) === da.id) {
          join.push(res);
        }
      });
    });
    setMerge(join);

    let data = entry.items.find(
      (sha, index) => sha?.fields?.color === bodyColor
    );
    setBodylist(data);

    if (data) {
      setBack(data?.fields?.id);
      document.documentElement.style.setProperty("--theme-img", data.bodyColor);
    }
  }, []);

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "dashboardSection",
      order: "sys.id",
    });
    setWork(entry.items);
  }, []);
  useEffect(() => {}, [listdata, merge]);


  return (
    <>
      <div className={`confimation-hero-section main-img-${back}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="confirmation-hero-content success-hero-content">
                {firstTimeVisit && firstTimeVisit == true ? (
                  <p className="congratulations">
                   Congratulations! Get ready to shop your shape and love
                    your closet.
                  </p>
                ) : (
                  ""
                )}
                <h4>
                  Your ShapeShopp
                  <sup>TM</sup> Color is
                </h4>
                <h2>{bodyColor}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="img-content">
          {back && <img src={back.bodyColor} alt="" height="390px" />}
        </div>
      </div>
      <div className=" section-dash">
        <div className="dress-steps">
          <div className="row">
            {work?.slice(1, 3)?.map((element, key) => (
              <div key={key} className="col-6 dash_main">
                <a href={element?.fields?.ids}>
                  <div className="dress-steps-item dash">
                    <div className="step-label dash">
                      {/* <p>{element?.fields?.step}</p> */}
                    </div>
                    <div className="dash_content">
                      <div className="step-content pt-2 dash">
                        <h4>{element?.fields?.style}</h4>

                        <p>{element?.fields?.descriptions}</p>
                      </div>
                      <div className="dress-1-img dash">
                        <img
                          src={element?.fields?.styleImage?.fields?.file?.url}
                          alt=""
                          className="img-fluid dash"
                        />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="howitworks-container streamline-containers">
        <div className="container">
          <div className="how-it-works-icon font-lg-32 dashboard">
            <div className="play-icon"></div>
            <span>Your </span> Body Shape
          </div>
          <FadeInSection>
            <div className="bodyshape-content">
              <div className="get-start-img">
                <img
                  src={bodylist?.fields?.image?.fields?.file?.url}
                  className="img-fluid rounded-start size"
                />
              </div>
              <h2 className="">
                <div>
                  <ul className="list-color">
                    {bodylist?.fields?.listing?.content.map((ele, key) => (
                      <ul key={key}>
                        {ele?.content?.map((el, key) => (
                          <li key={key}>{el?.value}</li>
                        ))}
                      </ul>
                    ))}
                  </ul>
                </div>
              </h2>
            </div>
            <div className="color-bar loginbar">
              <div className="multi-color-section"></div>
            </div>
          </FadeInSection>
        </div>
      </div>
    </>
  );
};

export default LoginHero;
