import React, { useEffect, useState, useRef, useMemo } from "react";
import { createClient } from "contentful";
import ReactPlayer from "react-player";
import Vimeo from "@u-wave/react-vimeo";
import Loader from "../Loader.js";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const VideoComponent = ({ ele }) => {
  const [video, setVideo] = useState(ele);
  const [play, setPlay] = useState(false);
  const [loading, setLoading] = useState(false);

  useMemo(() => {
    setVideo(ele);
    setPlay(false);
  }, [ele]);

  const handlePlay = (e) => {
    e.preventDefault();
    setPlay((value) => !value);
    setLoading((value) => !value);
  };

  const triggerGoogleAnalyticID = (video) => {
    // console.log("sddsdsd", document.getElementById(video.color + ' ' + video.title).click());
    document.getElementById(video.color + ' ' + video.title).click();
  };

  return (
    <>
      <>
        <div>
          <Vimeo
            className="react-player"
            id={`${video.color} ${video.title}`}
            onPlay={(e) => triggerGoogleAnalyticID(video)}
            video={video.url}
            onReady={(ready) => {
              ready._window.postMessage(
                "<style>body{background:red}</style>",
                "*"
              );

              setLoading(false);
            }}
            onLoaded={(ready) => {}}
          />
        </div>
      </>
      <h4>{video.title}</h4>
    </>
  );
};

export default function VideoCrousel({ videos }) {
  const [videoList, setVideoList] = useState(videos);
  const [video, setVideo] = useState(videos[0]);
  const [loading, setLoading] = useState(true);
  const [slide, setSlide] = useState({ crr: 0, total: videos.length });
  const thumbnailRef = useRef(null);
  const [loader, setloader] = useState(false);

  useEffect(() => {
    if (videos.length) {
      setLoading(false);
      setVideo(videos[0]);
      setSlide((value) => ({ ...value, total: videos.length }));
      setVideoList(videos);
    }
  }, [videos]);

  const onPrevHandler = (e) => {
    e.preventDefault();
    if (slide.crr > 0) {
      setSlide((value) => ({ ...value, crr: value.crr - 1 }));
      setVideo(videoList[slide.crr - 1]);
    } else {
      setSlide((value) => ({ ...value, crr: 4 }));
      setVideo(videoList[4]);
    }
  };

  const onNextHandler = (e) => {
    e.preventDefault();
    if (slide.crr < slide.total - 1) {
      setSlide((value) => ({ ...value, crr: value.crr + 1 }));
      setVideo(videoList[slide.crr + 1]);
    } else {
      setSlide((value) => ({ ...value, crr: 0 }));
      setVideo(videoList[0]);
    }
  };

  return (
    <section className="carousel-wrapper">
      <section className="carousel">
        <div className="slide">
          <div className="image-container">
            {!loading ? <VideoComponent ele={video} /> : <></>}
          </div>
        </div>
        <img
          className="carousel-button prev"
          onClick={onPrevHandler}
          src="../images/back-arrow.webp"
          alt=""
        />

        {/* </button> */}
        <img
          className="carousel-button next"
          onClick={onNextHandler}
          src="../images/back-arrow.webp"
          alt=""
        />
        {/* </button> */}
        <nav className="dots">
          <ul className="items">
            <li className={slide.crr === 0 ? "item current" : "item"}>
              <button className="c-button"></button>
            </li>
            <li className={slide.crr === 1 ? "item current" : "item"}>
              <button className="c-button"></button>
            </li>
            <li className={slide.crr === 2 ? "item current" : "item"}>
              <button className="c-button"></button>
            </li>
            <li className={slide.crr === 3 ? "item current" : "item"}>
              <button className="c-button"></button>
            </li>
            <li className={slide.crr === 4 ? "item current" : "item"}>
              <button className="c-button"></button>
            </li>
          </ul>
        </nav>
      </section>
    </section>
  );
}
