import React, { useState,useEffect } from "react";
import PricingPlans from "../PricingPlans/PricingPlans";
import BodyShape from "../Home/GetStarted/BodyShape";
import Form from "../PricingPlans/Form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

function PricingPlan() {
  const [display, setDisplay] = useState(1);
  const [val, setVal] = useState("");
  const [plan, setPlan] = useState({
    plantype: "",
    planid: "",
    planStyle: "",
  });
  const [form, setForm] = useState("");
  const [style, setStyle] = useState("");
  const [color, setColor] = useState("");
  const [background, setBackground] = useState("");
  const [showcontinue, setShowContinue] = useState(true);
  const [subplans, setSubplans] = useState("");

  const bounce = cssTransition({
    enter: "animate__animated animate__bounceIn",
    exit: "animate__animated animate__bounceOut",
  });

  const nextPage = () => {
    if (display === 1 && plan.planid === "") {
    } else if (display === 2 && val === "") {
    } else {
      setDisplay(display + 1);
      window.scrollTo(0, 0);
    }
  };

  const pricing = () => {
    if (display === 1 && val === "") {
    } else {
      setDisplay(display + 1);
      window.scrollTo(0, 0);
    }
  };

  const back = () => {
    setDisplay((prev) => prev - 1);
  };

  const navigate = useNavigate();
  const logPage = () => {
    navigate("/login");
  };


  const [section4, setSection4] = useState();

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "shapeshoppSection4",
      order: "sys.id",
    });
    setSection4(entry.items);
  },[]);

  return (
    <>
      {display == 1 ? (
        <div className="hero-section pricing-hero-section pricingstreamline">
          <div className="container">
            <div className="left-col-hero">
              <div className="left-col-title">
                <h1 className="get">PLANS</h1>
              </div>
              <p>
                Choose your membership level..
              </p>
            </div>
          </div>
          <div className="right-col-hero pricing-banner-img"></div>
        </div>
      ) : (
        ""
      )}

      <div className="getstarted-content"></div>
      {display === 1 && (
        <PricingPlans
          nextPageHandler={nextPage()}
          back={""}
          setPlan={setPlan}
        />
      )}
      {display === 2 && (
        <BodyShape
          nextPageHandler={nextPage()}
          setVal={setVal}
          setPlan={setPlan}
          plan={plan}
          val={val}
          setStyle={setStyle}
          setColor={setColor}
          setBackground={setBackground}
          subplans={subplans}
          setSubplans={setSubplans}
        />
      )}
      {(display === 3 || display === 4) && (
        <Form
          setForm={setForm}
          display={display}
          setPlan={setPlan}
          setDisplay={setDisplay}
          setStyle={setStyle}
          plan={plan}
          val={val}
          setVal={setVal}
          back={back}
          pricing={pricing}
          style={style}
          color={color}
          setColor={setColor}
          background={background}
          setBackground={setBackground}
          subplans={subplans}
          setSubplans={setSubplans}
        />
      )}
      <div className="container">
        {display === 1 || display === 2 ? (
          <div className="row">
            <div className="body_shape already-account">
              <h5>
                Already have an account? <u onClick={logPage}> Log in here</u>
              </h5>

              <div className="cont"></div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="howitworks-container price-streaming mobileview-width">
        <div className="container">
        <div className="howitworks-content">
         {section4?.map((ele, key)=>
         <h2 key={key} className="font-lg-32">
           <div dangerouslySetInnerHTML={{ __html: ele?.fields?.text }}></div>
         </h2>
       )}
       </div>

          <div className="color-bar">
            <div className="multi-color-section"></div>
          </div>
        </div>

        <div className="container"></div>
      </div>

      <ToastContainer
        transition={bounce}
        position="bottom-right"
        autoClose={0.5}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
}

export default PricingPlan;
