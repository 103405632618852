import React, { useEffect } from "react";
import { useState } from "react";
import data from "./body.json";
import "./BodyShape.css";
import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const BodyShape = ({
  setVal,
  val,
  setStyle,
  setColor,
  setBackground,
  nextPageHandler,
}) => {
  const [selectImg, setSelectImg] = useState(15);
  const [avatar, setAvatar] = useState();

  useEffect(async() => {
    const entry = await client.getEntries({
      content_type: "bodyShape",
      order: "sys.id",
    });
    setAvatar(entry?.items);
  }, []);
  
  useEffect(() => {
   
    
  }, [avatar]);
 
  const handleClick = (val, style_number, Color, Background, key) => {
    setStyle(style_number);
    setVal(val);
    setSelectImg(key);
    setColor(Color);
    setBackground(Background);
  };

  return (
    <div className="body-shape-comp get-start">
      <div className="container">
        <div className="heading-getstarted">
          <h6 className="get">GET STARTED</h6>
          <h2 className="get-text ">
            Select a body shape that best resembles your figure.
          </h2>
        </div>

        <div className="row body_shape">
          {data?.map((element, key) => (
            <div key={key} className="col-lg-4">
              <div className="body-shap-wrapper">
                <div
                  className="get-start0card mb-3"
                  key={key}
                >
                  <div
                    className={
                      key === selectImg
                        ? "get-start-img active"
                        : "get-start-img"
                    }
                  >
                  
                    <img
                      src={element.image}
                      className="img-fluid rounded-start size"
                      alt="..."
                    />
                  </div>
                    
                  <div 
                  
                  className={element.color === "Yellow" || element.color === "Blue"? "text-continue continue-btn  animate__animated animate__fadeInRight" : "text-continue continue-btn  animate__animated animate__fadeInLeft" }>
                    <div key={key}
                      className="card-text cardlist"
                      dangerouslySetInnerHTML={{ __html: element.list }}
                    ></div>

                    <button
                      id={element.color}
                      type="button"
                      className="btn btn-lg-primary "
                      onClick={(e) =>
                        handleClick(
                          element.id,
                          element.style_number,
                          element.color,
                          element.background,
                          key
                        )
                      }
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BodyShape;
