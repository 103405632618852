import React from "react";
import "./Confirmation-Hero.css";

export const ConfirmationHero = (props) => {
  

  return (
    <div className="login_sucess_wrapper nav-gap">
      <div
        className={`confirmation_banner confimation-hero-section main-img-${props.backImage}`}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="confirmation-red">
                <div className="confirmation-top">
                  <h4>Your ShapeShopp<sup>TM</sup> Color is</h4>
                  <h2>{props.color}</h2>

                  <div className="congratuation-content-bottom pt-4">
                    <h3 className="font-lg-32">
                    Congratulations!
                      <br />
                      This is what you've been waiting for.
                    </h3>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
