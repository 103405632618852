import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CreatePassword.css";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";

const CreatePassword = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const [password, setPassword] = useState("");
  const [passwordHint, setPasswordHint] = useState(false);
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters
  const [disableButton, setDisableButton] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const navigate = useNavigate();
  const mustContainData = [
    ["an uppercase letter (A-Z)", containsUL],
    ["a lowercase letter (a-z)", containsLL],
    ["a number (0-9)", containsN],
    ["a special character (!@#$)", containsSC],
    ["at least 8 characters", contains8C],
  ];
  const validatePassword = (event) => {
    let passwordOne = event.target.value;
    setValues((element) => {
      return {
        ...element,
        password: passwordOne,
      };
    });
    setPassword(passwordOne);
    setPasswordHint(true);
    let temp_all_valid = true;
    setDisableButton(false);
    // has uppercase letter
    if (passwordOne.toLowerCase() !== passwordOne) {
      setContainsUL(true);
    } else {
      setContainsUL(false);
      temp_all_valid = false;
    }

    // has lowercase letter
    if (passwordOne.toUpperCase() !== passwordOne) {
      setContainsLL(true);
    } else {
      setContainsLL(false);
      temp_all_valid = false;
    }
    // has number
    if (/\d/.test(passwordOne)) {
      setContainsN(true);
    } else {
      setContainsN(false);
      temp_all_valid = false;
    }
    // has special character
    if (/[~`!#@$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(passwordOne)) {
      setContainsSC(true);
    } else {
      setContainsSC(false);
      temp_all_valid = false;
    }
    // has 8 characters
    if (passwordOne.length >= 8) {
      setContains8C(true);
    } else {
      setContains8C(false);
      temp_all_valid = false;
    }
    // all validations passed
    if (temp_all_valid) {
      setPasswordHint(false);
      setDisableButton(true);
    }
  };

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const [values2, setValues2] = React.useState({
    password2: "",
    showPassword2: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => {
    setValues2({ ...values2, showPassword2: !values2.showPassword2 });
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const resetPasswordHandler = async (e) => {
    e.preventDefault();
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if (values.password !== values2.password2) {
      setTimeout(() => {
        setError("");
      }, 2000);
      return setError("Passwords don't match");
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/v1/password/reset`,
        {
          token: token,
          password: values.password,
        },
        config
      );
      if (data) {
        navigate("/login");
      }
      setSuccess("data".data);
    } catch (error) {
      setError(error.response.data.error);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const setStateHandler = (e) => {
    setValues2((element) => {
      return {
        ...element,
        password2: e.target.value,
      };
    });
  };


  return (
    <>
      <div className="heading-getstarted"> </div>
      <div className="password-form">
        <h1>Reset Your Password</h1>
        {error && <span className="error-message">{error} </span>}

        <form onSubmit={resetPasswordHandler}>
          <div className="form-group-pass">
            <Input
              name="password"
              className="form-control"
              id="password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              placeholder="Enter New Password"
              onChange={(e) => validatePassword(e)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              required
            />
          </div>
          {passwordHint === true ? (
            <div className="password-hint-sec">
              {mustContainData.map((item, key) => {
                if (item[1] === false) {
                  return (
                    <div className="password-hint-wrapper" key={key}>
                      <div>
                        <div className="mr-2">
                          <ClearIcon className="alert-red" />
                        </div>
                      </div>
                      <div style={{ paddingLeft: 5 }}>
                        <p className="mb-0" style={{ fontSize: 14 }}>
                          Must contain {item[0]}
                        </p>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="password-hint-wrapper" key={key}>
                      <div>
                        <div className="mr-2">
                          <CheckIcon className="alert-green" />
                        </div>
                      </div>
                      <div style={{ paddingLeft: 5 }}>
                        <p className="mb-0" style={{ fontSize: 14 }} promo>
                          Must contain {item[0]}
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            ""
          )}

          <div className="form-group-pass">
            <Input
              name="password"
              className="form-control"
              id="password2"
              type={values2.showPassword2 ? "text" : "password"}
              placeholder="Confirm Password"
              onChange={(e) => setStateHandler(e)}
              value={values2.password2}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword2}
                  >
                    {values2.showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              required
            />
          </div>
          <button type="submit" className="btn btn-lg-primary btn-block mb-4">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default CreatePassword;
