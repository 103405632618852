import React, { useState, useEffect } from "react";
import "./ShapeBasics.css";
import shape from "../loginShape.json";

const ShapeBasics = () => {
  const [shapeObject, setShapeObject] = useState("");
  const selectedColor = localStorage.getItem("color");

  useEffect(() => {
    let data = shape.find((sha, index) => sha.color === selectedColor);
    setShapeObject(data);
  }, []);

  const printPdf = () => {
    window.open(`${shapeObject.pdf}`);
  };

  return (
    <div>
      <div className="howitworks-container streamline-containers">
        <div className="container">
          <div className="how-it-works-icon font-lg-32 sheet">
            <div className="play-icon"></div>
            <span>Shape </span> Cheat Sheet
            <div className="print desktop_view">
            <p onClick={printPdf}>
              <u id="printable-version">Cheat Sheet Printable version</u>
            </p>
          </div>
          </div>
          <div className="print mobile_view">
            <p onClick={printPdf}>
              <u id="printable-version">Cheat Sheet Printable version</u>
            </p>
          </div>
          <div className="row ">
            <>
              <div className="col-md-3 shapebasic_row">
                <div className="clothes">
                  <div className="tops">Tops</div>
                </div>
                <div className="style-image">
                  <img
                    className="shapebasic_img"
                    src={shapeObject?.top_image}
                    alt=""
                  />
                </div>
                <div className={`lists-style ${shapeObject?.color}`}>
                  <ol
                    className="list-styling"
                    dangerouslySetInnerHTML={{
                      __html: shapeObject?.shape_tops,
                    }}
                  ></ol>
                </div>
              </div>
              <div className="col-md-3 shapebasic_row">
                <div className="clothes">
                  <div className="tops">Pants</div>
                </div>
                <div className="style-image">
                  <img
                    className="shapebasic_img"
                    src={shapeObject?.pants_image}
                    alt=""
                  />
                </div>
                <div className={`lists-style ${shapeObject?.color}`}>
                  <ol
                    className="list-styling"
                    dangerouslySetInnerHTML={{
                      __html: shapeObject?.shape_pants,
                    }}
                  ></ol>
                </div>
              </div>
              <div className="col-md-3 shapebasic_row">
                <div className="clothes">
                  <div className="tops">Skirts</div>
                </div>
                <div className="style-image">
                  <img
                    className="shapebasic_img"
                    src={shapeObject?.skirts_image}
                    alt=""
                  />
                </div>
                <div className={`lists-style ${shapeObject?.color}`}>
                  <ol
                    className="list-styling"
                    dangerouslySetInnerHTML={{
                      __html: shapeObject?.shape_skirts,
                    }}
                  ></ol>
                </div>
              </div>
              <div className="col-md-3 shapebasic_row">
                <div className="clothes">
                  <div className="tops">Dresses</div>
                </div>
                <div className="style-image">
                  <img
                    className="shapebasic_img"
                    src={shapeObject?.dresses_image}
                    alt=""
                  />
                </div>
                <div className={`lists-style ${shapeObject?.color}`}>
                  <ol
                    className="list-styling"
                    dangerouslySetInnerHTML={{
                      __html: shapeObject?.shape_dresses,
                    }}
                  ></ol>
                </div>
              </div>
            </>
          </div>

          
        </div>

        <div className="email-section">
          <img className="message_img" src="/images/Email_icon.png" alt="" />
          <p className="fw-500 font-lg-18">
            Watch for emails with training videos and messages from your stylist
            to help you perfect your ShapeShopp wardrobe!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShapeBasics;
