import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./Payment.css";
import PaymentForm from "./PaymentForm";
import data from "../Home/GetStarted/body.json";
import datas from "./pricing.json";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`);

const Payment = (props) => {
  const [image, setImage] = useState();
  const [list, setList] = useState();
  useEffect(() => {
    datas.filter((dat) => {
      if (dat.plan_type === props.plan) {
        setList(dat);
      }
    });
  }, [props.plan]);

  useEffect(() => {
    window.scrollTo(0, 0);
    data.filter((dat) => {
      if (dat.id === props.val) {
        setImage(dat);
      }
    });
  }, []);
  return (
    <>
      <Elements stripe={stripePromise}>
        <PaymentForm
          plan={props.plan}
          userID={props.userID}
          color={props.color}
          background={props.background}
          stylenumber={props.stylenumber}
          customerID={props.custID}
          setCustID={props.setCustID}
          tokens={props.tokens}
          planID={props.planID}
          list={props.list}
          store={props.store}
          setStore={props.setStore}
          size={props.size}
          setSize={props.setSize}
          selected={props.selected}
          setSelected={props.setSelected}
          payloadval={props.payloadval}
          setPayloadVal={props.setPayloadVal}
          promoCode={props.promoCode}
        />
      </Elements>
    </>
  );
};

export default Payment;
