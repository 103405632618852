import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import "./my-account.css";
import axios from "axios";
import data from "../Home/GetStarted/body.json";
import Modal from "react-modal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentUpdate from "./PaymentUpdate";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import Profilecontext from "../../Context/Profilecontext";
import Loader from "../Loader.js";
import PaymentUpdation from "./PaymentUpdation";
Modal.setAppElement("#root");

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`);

function MyAccount() {
  const { profilepic, setProfilepic, navset, setNavset } =
    useContext(Profilecontext);
  const [profiles, setProfiles] = useState();
  const [subModal, setSubModal] = useState(false);
  const [subUpdateModal, setSubUpdateModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [buyPlanModal, setBuyPlanModal] = useState(false);
  const [size, setSize] = useState({});
  const [picture, setPicture] = useState("");
  const [read, setRead] = useState(true);
  const [height, setHeight] = useState({});
  const [color, setColor] = useState("");
  const [plandata, setPlandata] = useState();
  const [profileUpdate, setProfileUpdate] = useState();
  const [updatesubscription, setUpdateSubscription] = useState(true);
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [loader, setLoader] = useState(false);
  const [cancelDoneModal, setCancelDoneModal] = useState(false);
  const [newplanModal, setNewPlanModal] = useState(false);
  const [subUpdatePromoModal, SetSubUpdatePromoModal] = useState(false);
  const [created, setCreated] = useState();
  const [dayLeft, setDayLeft] = useState();
  const [DOB, setDOB] = useState();
  const [newPayModal, setNewPayModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newCol, setNewCol] = useState("");
  const [bodyShapeModal, setBodyShapeModal] = useState(false);
  const [bodyEle, setBodyEle] = useState("");
  const [bodyKey, setBodyKey] = useState("");

  useEffect(() => {
    profileData();
    window.scrollTo(0, 0);
  }, [updatesubscription, cancelSubscription]);

  const fromUserValue = {
    first_name: "",
    last_name: "",
    birthday: "",
    phone: "",
    body_shape_color: "",
    profile_pic: "",
    plan_id: "",
    country_code: "+1",
    email: "",
  };
  const [userData, setuserData] = useState(fromUserValue);

  const onChangePicture = (e) => {
    setPicture(e.target.files[0]);
  };

  let profileTok = localStorage.getItem("tokenn");

  const bindValue = (value) => {
    if (value !== "" && value !== undefined) {
      userData["first_name"] = value?.first_name;
      userData["last_name"] = value?.last_name;
      userData["email"] = value?.email;
      userData["phone"] = value?.phone;
      userData["body_shape_color"] = value?.body_shape_color;
      userData["birthday"] = value?.birthday;

      setuserData({ ...userData });

      setHeight(
        heightOptions?.filter((x) => x?.label === value?.heights[0]?.name)[0]
      );
      let asd = data?.find((x) => x?.color === value?.body_shape_color);

      setColor(asd.color);

      setSize(
        sizeOptions?.filter((x) => x?.label === value?.sizes[0]?.name)[0]
      );
    }
  };
  const handleClick = (values) => {
    setNewCol(values);
    setColor(values.color);
    setModalIsOpen(false);
    setNavset(false);
    setBodyShapeModal(false);
  };

  // Api for GET PROFILE

  const profileData = () => {
    setLoader(true);

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/v1/users/get_profile`, {
        headers: {
          Authorization: `Bearer ${profileTok}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setProfilepic(response?.data?.user?.profile_pic);
        setProfiles(response?.data?.user);
        setCreated(response?.data?.user?.subscriptions[0]?.created_at);
        setDOB(moment(response?.data?.user?.birthday).format("MM/DD/YYYY"));
        let planUpadtes = {};
        planUpadtes["stripe_customer_id"] =
          response?.data?.user?.stripe_customer_id;
        planUpadtes["price_id"] =
          response?.data?.user?.subscriptions[0]?.price_id;
        setProfileUpdate({ ...planUpadtes });
        bindValue(response?.data?.user);
        formatNumber(response?.data?.user?.phone, "get");
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  };

  const editInfo = (e) => {
    setRead(false);
  };

  // Api for UPDATE PROFILE

  const handleSubmission = (e) => {
    setRead(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${profileTok}`);

    var formData = new FormData();

    if (picture !== "") {
      formData.append("user[profile_pic]", picture);
      setLoader(true);
    }

    if (profiles.birthday !== userData.birthday) {
      formData.append("user[birthday]", userData.birthday);
      setDOB(
        moment(userData.birthday).format("MM/DD/YYYY")
      )
    }

    if (profiles.first_name !== userData.first_name) {
      formData.append("user[first_name]", userData.first_name);
      setProfiles({
        ...profiles,
        first_name: userData.first_name
      })
    }

    if (profiles.last_name !== userData.last_name) {
      formData.append("user[last_name]", userData.last_name);
      setProfiles({
        ...profiles,
        last_name: userData.last_name
      })
    }

    if (profiles.phone !== userData.phone.replace('+1', '')) {
      const newPhone = userData.phone.split("+1")[1]
      formData.append("user[phone]", newPhone);
      setProfiles({
        ...profiles,
        phone: newPhone
      })
    }

    if (profiles.body_shape_color !== color) {
      formData.append("user[body_shape_color]", color);
      setProfiles({
        ...profiles,
        body_shape_color: color
      })
    }

    if (profiles.heights[0].id.toString() !== height?.value) {
      formData.append("height_ids[]", height?.value);
      setHeight(height)
    }

    if (profiles.sizes[0].id.toString() !== size?.value) {
      formData.append("size_ids[]", size?.value);
      setSize(size)
    }

    if (!formData.entries().next().value) return

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/users/update_profile`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setProfilepic(result?.user?.profile_pic);
        if (picture !== "") {
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  };

  // API to UPDATE SUBSCRIPTION

  const planUpdate = () => {
    setSubUpdateModal(false);
    setLoader(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_BASE_URL}/v1/subscriptions/${profiles?.subscriptions[0].id}`,
        {
          subscription: profileUpdate,
        },
        {
          headers: {
            Authorization: `Bearer ${profileTok}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setUpdateSubscription(false);
        setLoader(false);
        setBuyPlanModal(true);
        profileData();
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  };

  const sizeOptions = [
    { label: "Regular", value: "4" },
    { label: "Plus", value: "5" },
  ];

  const heightOptions = [
    { label: "Average", value: "3" },
    { label: "Tall", value: "2" },
    { label: "Petite", value: "1" },
  ];

  const planNames = [
    {
      label: "smart ($9/mo.)",
      value: "prod_LQfeWIw87FzeJA",
      price: "price_1KjoJMEO6Kb9LR1Ssl7wPVi9",
    },
    {
      label: "savvy ($49/mo.)",
      value: "prod_LseX0Oqzuee1Yz",
      price: "price_1LBKsfEO6Kb9LR1SwaBk94eU",
    },
    {
      label: "custom ($99/mo.)",
      value: "prod_LseatnWmt0sZzr",
      price: "price_1LBKu5EO6Kb9LR1SIisdrhZq",
    },
  ];

  useEffect(() => {
    if (profiles?.plan?.name) {
      const findPlanName = planNames.find(
        (x) =>
          x.label.split(" ")[0].toLowerCase() ===
          profiles.plan.name.toLowerCase()
      );
      if (findPlanName) {
        setProfileUpdate((profileData) => {
          return {
            ...profileData,
            plan_id: findPlanName.value,
          };
        });
        setPlandata(findPlanName.value);
      }
    }
  }, [profiles, dayLeft]);

  useEffect(() => {
    var create = moment(profiles?.subscriptions[0]?.expire_at, "YYYY-MM-DD");
    var current = moment().startOf("days");
    //Difference in number of days
    let daysLeft = moment.duration(create.diff(current)).asDays();
    setDayLeft(daysLeft);
  }, []);

  var renewalDate = moment(profiles?.subscriptions[0]?.expire_at).format("MM/DD/YYYY");
  var monthlyPlan = profiles?.subscriptions[0]?.amount_in_cents / 100;
  // CREDIT CARD VALIDATION

  let currentCardDate = moment().format("M/YYYY");
  let expirydatecard = `${profiles?.exp_month}${"/"}${profiles?.exp_year}`;

  // API for CANCEL SUBSCRIPTION

  const cancel = () => {
    setCancelModal(false);
    setLoader(true);
    var data = "";
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/subscriptions/cancel_subscription`,
      headers: {
        Authorization: `Bearer ${profileTok}`,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setLoader(false);
        setCancelDoneModal(true);
        profileData();
      })
      .catch(function (error) {
        setLoader(true);
      });
  };

  // API FOR BUY PLAN

  const buyPlanUpdate = async (e) => {
    setSubUpdateModal(false);
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/v1/subscriptions`,
        {
          subscription: profileUpdate,
        },
        {
          headers: {
            Authorization: `Bearer ${profileTok}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(() => {
        SetSubUpdatePromoModal(false);
        setLoader(false);
        profileData();
        setBuyPlanModal(true);
      })
      .catch(function (error) {
        setLoader(true);
      });
  };

  const back = () => {
    setCancelModal(false);
  };

  const bodyCancel = () => {
    setBodyShapeModal(false);
    setModalIsOpen(true);
  };

  const addplan = (res, key) => {
    setProfileUpdate((profileData) => {
      if (key === 1) {
        window._klOnsite = window._klOnsite || [];
        window._klOnsite.push(["openForm", "TmabZ6"]);
      }
      if (key === 2) {
        window._klOnsite = window._klOnsite || [];
        window._klOnsite.push(["openForm", "UBj3dN"]);
      }
      return {
        ...profileData,
        plan_id: res.value,
        price_id: res.price,
      };
    });
    setPlandata(res.value);
  };

  const buySubscription = (res, key) => {
    setProfileUpdate((profileData) => {
      return {
        ...profileData,
        plan_id: res.value,
        price_id: res.price,
      };
    });
    setPlandata(res.label);
    if (key === 1) {
      window._klOnsite = window._klOnsite || [];
      window._klOnsite.push(["openForm", "TmabZ6"]);
    }
    if (key === 2) {
      window._klOnsite = window._klOnsite || [];
      window._klOnsite.push(["openForm", "UBj3dN"]);
    }
  };

  var x = `${profiles?.country_code}+${profiles?.phone}`
    ?.replace(/\D+/g, "")
    .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");

  const formatNumber = (value, change) => {
    if (change === "post") {
      setuserData({
        ...userData,
        phone: "+" + value,
      });
    } else {
      setuserData({
        ...userData,
        phone: "+1" + value,
      });
    }
  };

  const open = () => {
    setNewPlanModal(false);
    setNewPayModal(true);
  };
  return (
    <div className="my-account-comp">
      <div className="container">
        <div className="heading-getstarted">
          <h6 className="get">MY ACCOUNT</h6>
        </div>

        <div className="account-title">
          <h2 className="font-lg-24">Personal Information</h2>
          {read ? (
            <div>
              <div className="readonly">
                <div className="row">
                  <div className="col-md-4 col-6">
                    <div className="fafa-icon-users">
                      <i className="fa fa-user" aria-hidden="true"></i>
                      <div className="fafa-icon-myaccount">
                        <h6>First Name</h6>
                        {profiles?.first_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="fafa-icon-users">
                      <i className="fa fa-user" aria-hidden="true"></i>
                      <div className="fafa-icon-myaccount">
                        <h6>Last Name</h6>
                        {profiles?.last_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="fafa-icon-users">
                      <i className="fa fa-globe" aria-hidden="true"></i>
                      <div className="fafa-icon-myaccount">
                        <h6>Zipcode</h6>
                        {profiles?.zip_code}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="fafa-icon-users">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      <div className="fafa-icon-myaccount">
                        <h6>Phone</h6>+{x}
                      </div>
                    </div>
                  </div>
                  {profiles?.birthday == null ? (
                    ""
                  ) : (
                    <div className="col-md-4 col-6">
                      <div className="fafa-icon-users">
                        <i
                          className="fa fa-birthday-cake"
                          aria-hidden="true"
                        ></i>
                        <div className="fafa-icon-myaccount">
                          <h6>Birthday</h6>
                          {DOB}
                        </div>
                      </div>
                    </div>
                  )}

                  {profiles?.sizes.length == 0 ? (
                    ""
                  ) : (
                    <div className="col-md-4 col-6">
                      <div className="fafa-icon-users">
                        <i className="fa fa-user" aria-hidden="true"></i>
                        <div className="fafa-icon-myaccount">
                          <h6>Size</h6>
                          {size.label}
                        </div>
                      </div>
                    </div>
                  )}

                  {profiles?.heights.length == 0 ? (
                    ""
                  ) : (
                    <div className="col-md-4 col-6">
                      <div className="fafa-icon-users">
                        <i className="fa fa-user" aria-hidden="true"></i>
                        <div className="fafa-icon-myaccount">
                          <h6>Height</h6>
                          {height.label}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-4 col-6">
                    <div className="fafa-icon-users">
                      <i className="fa fa-user" aria-hidden="true"></i>
                      <div className="fafa-icon-myaccount">
                        <h6>BodyShape Color</h6>
                        {profiles?.body_shape_color}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="fafa-icon-users">
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                      <div className="fafa-icon-myaccount">
                        <h6>Email</h6>
                        {profiles?.email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edit-btn">
                <button
                  className="btn edit-info"
                  type="button"
                  onClick={(e) => editInfo()}
                >
                  Edit Personal Information
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="account-fields">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          name="first_name"
                          type="text"
                          className="form-control"
                          id="firstname"
                          value={userData["first_name"]}
                          onChange={(txt) => {
                            setuserData({
                              ...userData,
                              first_name: txt.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          name="last_name"
                          type="text"
                          className="form-control"
                          value={userData["last_name"]}
                          onChange={(txt) => {
                            setuserData({
                              ...userData,
                              last_name: txt.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    {profiles?.sizes.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group ">
                          <label htmlFor="exampleFormControlSelect1">
                            Choose Size Category
                          </label>
                          <Select
                            options={sizeOptions}
                            value={size}
                            onChange={(txt) => {
                              setSize(txt);
                            }}
                            labelledBy="Select"
                          />
                        </div>
                      </div>
                    )}
                    {profiles?.heights.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group ">
                          <label htmlFor="exampleFormControlSelect1">
                            Choose Height Category
                          </label>
                          <Select
                            options={heightOptions}
                            value={height}
                            onChange={(txt) => {
                              setHeight(txt);
                            }}
                            labelledBy="Select"
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Phone</label>
                        <PhoneInput
                          className="form-control"
                          name="phone"
                          inputProps={{
                            required: true,
                            autoFocus: false,
                          }}
                          countryCodeEditable={false}
                          disableDropdown={true}
                          country={"us"}
                          onChange={(txt) => {
                            formatNumber(txt, "post");
                          }}
                          value={userData["phone"]}
                          required
                        />
                      </div>
                    </div>
                    {profiles?.birthday == null ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Birthday</label>
                          <input
                            name="birthday"
                            type="date"
                            className="form-control"
                            value={userData["birthday"]}
                            onChange={(txt) => {
                              setuserData({
                                ...userData,
                                birthday: txt.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="change-profile-photo">
                          Change Profile Photo
                        </label>
                        <input
                          className="change-profile-file"
                          type="file"
                          onChange={onChangePicture}
                        />
                      </div>
                    </div>
                    <>
                      <div className="col-md-6">
                        {profiles?.color_updated === true ? (
                          ""
                        ) : (
                          <>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlSelect1">
                                Body Shape Color
                              </label>
                              <p>
                                {newCol.color == undefined || newCol.color == ""
                                  ? profiles?.body_shape_color
                                  : newCol.color}
                              </p>

                              <div
                                className="bodyshape_change"
                                onClick={() => {
                                  setModalIsOpen(true);
                                  setNavset(true);
                                }}
                              >
                                <u>Change Body Shape</u>
                              </div>
                              <p>
                                You can only change this once. Contact us for
                                further assistance.
                              </p>
                              <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={() => {
                                  setModalIsOpen(false);
                                  setNavset(false);
                                }}
                              >
                                <>
                                  <div className="row body_shape">
                                    <h5>Select a Body Shape</h5>
                                    {data.map((element, key) => (
                                      <div
                                        key={key}
                                        // onClick={(e) => handleClick(element, key)}
                                        onClick={() => {
                                          setBodyShapeModal(true);
                                          setModalIsOpen(false);
                                          setBodyEle(element);
                                          setBodyKey(key);
                                        }}
                                        className="col-lg-6"
                                        // key={key}
                                      >
                                        <div className="get-start0card mb-3">
                                          <div className="row g-0 align-items-center">
                                            <div className="col-md-6">
                                              <div
                                                className={
                                                  element.color ===
                                                  profiles?.body_shape_color
                                                    ? "get-start-img selected"
                                                    : "get-start-img "
                                                }
                                              >
                                                <img
                                                  src={element.image}
                                                  className="img-fluid rounded-start"
                                                  alt="..."
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="card-body">
                                                <div
                                                  className="card-text cardlist"
                                                  dangerouslySetInnerHTML={{
                                                    __html: element.list,
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              </Modal>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  </div>
                  <div className="action-btn">
                    <button
                      className="btn edit-info"
                      type="button"
                      onClick={(e) => handleSubmission()}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
        <div className="account-title planarea">
          <h2 className="font-lg-24">Manage Plan</h2>
          {profiles?.subscriptions[0]?.subscription_status === "cancelled" ? (
            <>
              {profiles?.credit_card.length === 0 ? (
                <>
                  Your subscription is cancelled. You can continue using the
                  site until your free trial period ends.
                </>
              ) : (
                <>
                  Your subscription is cancelled. You will not be billed again
                  when the next billing period begins.
                </>
              )}
            </>
          ) : (
            ""
          )}

          {profiles?.subscriptions[0]?.subscription_status === "expired" &&
          profiles?.subscriptions[0]?.promo_code_applied === false ? (
            <>
              <div className="buy-area">
                <div className="buy-text">
                  <p>You don't have any active subscription.</p>
                </div>
                {profiles?.subscriptions[0]?.active === false ? (
                  <button
                    className="btn edit-info"
                    onClick={() => {
                      profiles?.credit_card?.length === 0
                        ? setNewPlanModal(true)
                        : setSubUpdateModal(true);
                    }}
                  >
                    Purchase plan
                  </button>
                ) : (
                  <button
                    className="btn edit-info"
                    onClick={() => {
                      profiles?.credit_card?.length === 0
                        ? setNewPlanModal(true)
                        : setSubUpdateModal(true);
                    }}
                  >
                    Buy plan
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="account-fields">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="font-lg-20 mb-3">Current Plan</h4>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        {profiles?.subscriptions[0]?.subscription_status ===
                        "expired" ? (
                          <p></p>
                        ) : (
                          <h4 className="font-lg-18">
                            {" "}
                            {profiles?.plan?.name[0].toUpperCase() +
                              profiles?.plan?.name.slice(1).toLowerCase()}
                          </h4>
                        )}
                      </div>
                      <div className="col-md-6">
                        {profiles?.subscriptions[0]?.subscription_status ===
                        "expired" ? (
                          <p></p>
                        ) : (
                          <h4 className="font-lg-18">
                            ${monthlyPlan} per month
                          </h4>
                        )}
                      </div>

                      <div className="col-md-12 mt-3">
                        {profiles?.subscriptions[0]?.subscription_status ===
                          "cancelled" &&
                        profiles?.subscriptions[0]?.active === true ? (
                          <p></p>
                        ) : (
                          <>
                            {" "}
                            {profiles?.subscriptions[0]?.promo_code_applied ===
                            true ? (
                              <>
                                {profiles?.subscriptions[0]?.active === false ||
                                profiles?.subscriptions[0]
                                  ?.subscription_status === "expired" ? (
                                  <>
                                    {dayLeft >= 23 &&
                                    profiles?.subscriptions[0]?.active ===
                                      true ? (
                                      <>
                                        {profiles?.subscriptions[0]
                                          ?.promo_code === "FREEFOREVER" ? (
                                          ""
                                        ) : (
                                          <p className="red">
                                            {" "}
                                            Your free trial will expire on
                                            {renewalDate}. Please purchase a
                                            plan to continue receiving helpful
                                            catalogs to enhance your shopping
                                            experience.{" "}
                                          </p>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {profiles?.subscriptions[0]?.promo_code ===
                                    "FREEFOREVER" ? (
                                      ""
                                    ) : (
                                      <p className="red">
                                        Your free trial expired {renewalDate}.
                                        Please update your plan to continue for
                                        all the great things we have in store
                                        just for you.
                                      </p>
                                    )}
                                  </>
                                ) : (
                                  <p>
                                    {
                                      profiles?.subscriptions[0]
                                        ?.promo_code_caption
                                    }
                                  </p>
                                )}
                              </>
                            ) : (
                              <p>Your next billing date is {renewalDate} </p>
                            )}
                          </>
                        )}
                      </div>
                      {profiles?.subscriptions[0].subscription_status ===
                        "cancelled" &&
                      profiles?.subscriptions[0]?.promo_code_applied ===
                        true ? (
                        <p>Your free trial will expire on {renewalDate}</p>
                      ) : (
                        ""
                      )}

                      {profiles?.subscriptions[0].subscription_status ===
                        "cancelled" &&
                      profiles?.subscriptions[0]?.promo_code_applied ===
                        false ? (
                        <p>Your next billing date is {renewalDate}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {profiles?.subscriptions[0]?.subscription_status ===
                "cancelled" ? (
                  <>
                    <div className="col-md-3">
                      <p
                        type=""
                        className="btn btn edit-infoo"
                        onClick={() =>
                          profiles?.credit_card.length === 0
                            ? setNewPlanModal(true)
                            : setSubUpdateModal(true)
                        }
                      >
                        Update Plan
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {profiles?.subscriptions[0]?.subscription_status ===
                "expired" ? (
                  <>
                    <div className="col-md-3">
                      <p
                        type=""
                        className="btn btn edit-infoo"
                        onClick={() =>
                          profiles?.credit_card.length === 0
                            ? setNewPlanModal(true)
                            : setSubUpdateModal(true)
                        }
                      >
                        Buy Plan
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {profiles?.subscriptions[0]?.subscription_status ===
                "active" ? (
                  <div className="action-btn">
                    <div className="Subscription-button">
                      <div className="row">
                        <div className="col-md-3">
                          <p
                            type=""
                            className="btn edit-info"
                            onClick={() => setCancelModal(true)}
                          >
                            Cancel Plan
                          </p>
                        </div>
                        <div className="col-md-3">
                          <p
                            type=""
                            className="btn btn edit-info"
                            onClick={() => setSubUpdateModal(true)}
                          >
                            Update Plan
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </form>
            </div>
          )}
        </div>

        {profiles?.credit_card === "" ? (
          ""
        ) : (
          <>
            <div className="account-title payments">
              <h2 className="font-lg-24">Payment Method</h2>
              <p>View and update your current payment method.</p>

              {expirydatecard === currentCardDate ? (
                <>
                  <p className="red">
                    Your credit card has expired. Please update your payment
                    method to continue receiving great content{" "}
                  </p>
                </>
              ) : (
                ""
              )}

              <div className="account-fields">
                <form>
                  <div className="row payment-area">
                    <div className="col-md-8">
                      <div className="row pp">
                        <div className="col-md-8">
                          {profiles?.credit_card === "null" ? (
                            <p>""</p>
                          ) : (
                            <>
                              <div className="pic">
                                <h4 className="font-lg-18">
                                  ••••••••••••{profiles?.credit_card}
                                </h4>
                              </div>
                            </>
                          )}
                          <div className=" expiry-date">
                            {profiles?.exp_month === undefined ||
                            profiles?.exp_month === "" ? (
                              <p></p>
                            ) : (
                              <p>
                                Expiring on {profiles?.exp_month}/
                                {profiles?.exp_year}{" "}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 action-btn-payment">
                          <p
                            className="change-button"
                            onClick={() => setSubModal(true)}
                          >
                            Change
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        className="cancel-modal"
        isOpen={cancelDoneModal}
        onRequestClose={() => setCancelDoneModal(false)}
      >
        <>
          <div className="cancel-planss">
            <p> Your plan has been cancelled.</p>
          </div>
        </>
      </Modal>

      <div className="modals">
        <Modal
          className="setting_modal"
          isOpen={subUpdateModal}
          onRequestClose={() => setSubUpdateModal(false)}
        >
          <>
            <div className="plans">
              {planNames?.map((res, key) => (
                <p
                  type=""
                  className={
                    plandata === res.value
                      ? "btn.plan-infoo active"
                      : "btn plan-info"
                  }
                  key={key}
                  onClick={() => addplan(res, key)}
                >
                  {res.label}
                </p>
              ))}
            </div>
            <button
              className="btn btn-light"
              onClick={planUpdate}
              // disabled={plandata}
            >
              Update
            </button>
          </>
        </Modal>
      </div>
      <div className="modals">
        <Modal
          className="setting_modal"
          isOpen={subUpdatePromoModal}
          onRequestClose={() => SetSubUpdatePromoModal(false)}
        >
          <>
            <div className="plans">
              {planNames?.map((res, key) => (
                <p
                  type=""
                  className={
                    plandata === res.label
                      ? "btn plan-infoo active"
                      : "btn plan-info"
                  }
                  key={key}
                  onClick={() => buySubscription(res, key)}
                >
                  {res.label}
                </p>
              ))}
            </div>
            <div className="text-center">
              <button
                className="btn edit-info"
                onClick={buyPlanUpdate}
                disabled={
                  !plandata ||
                  plandata === "savvy ($49/mo.)" ||
                  plandata === "custom ($99/mo.)"
                }
              >
                Update
              </button>
            </div>
          </>
        </Modal>
      </div>
      <Modal
        className="cancel-modal"
        isOpen={buyPlanModal}
        onRequestClose={() => setBuyPlanModal(false)}
      >
        <>
          <div className="cancel-planss">
            <p>Your plan has been updated.</p>
          </div>
        </>
      </Modal>
      <Modal
        className="setting_modal"
        isOpen={newplanModal}
        onRequestClose={() => setNewPlanModal(false)}
      >
        <>
          <div className="plans">
            {planNames?.map((res, key) => (
              <p
                type=""
                className={
                  plandata === res.value
                    ? "btn.plan-infoo active"
                    : "btn plan-info"
                }
                key={key}
                onClick={() => addplan(res, key)}
              >
                {res.label}
              </p>
            ))}
          </div>
          <button
            className="btn btn-light"
            onClick={open}
            // disabled={plandata}
          >
            Update
          </button>
        </>
      </Modal>
      <Modal
        className="cancel-modal"
        isOpen={cancelModal}
        onRequestClose={() => setCancelModal(false)}
      >
        <>
          <div className="cancel-plan">
            <p> Are you sure you want to cancel the subscription?</p>
            <div className="cancel-plan_btn">
              <button
                className="btn btn-light"
                onClick={cancel}
                id="cancel sub yes"
              >
                Yes
              </button>

              <button
                className="btn btn-light"
                onClick={back}
                id="cancel sub no"
              >
                No
              </button>
            </div>
          </div>
        </>
      </Modal>
      <Modal
        className="cancel-modal"
        isOpen={bodyShapeModal}
        onRequestClose={() => setBodyShapeModal(false)}
      >
        <>
          <div className="body-shapechange">
            <p>
              {" "}
              Are you sure you want to select this body shape?
              <br />
              This is a one time change.
            </p>
            <div className="get-start-img bodywidth">
              <img
                className="img-fluid rounded-start size"
                src={bodyEle?.image}
              />
            </div>
            <div className="cancel-plan_btn">
              <button
                className="btn btn-light"
                onClick={(e) => handleClick(bodyEle, bodyKey)}
                id="cancel sub yes"
              >
                Confirm
              </button>

              <button
                className="btn btn-light"
                onClick={bodyCancel}
                id="cancel sub no"
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      </Modal>
      <Modal
        className="setting_modal"
        isOpen={subModal}
        onRequestClose={() => setSubModal(false)}
      >
        <>
          <Elements stripe={stripePromise}>
            {/* for existing credit card */}
            <PaymentUpdate
              setSubModal={setSubModal}
              profileData={profileData}
            />
          </Elements>
        </>
      </Modal>
      <Modal
        className="setting_modal"
        isOpen={newPayModal}
        onRequestClose={() => setNewPayModal(false)}
      >
        <>
          <Elements stripe={stripePromise}>
            <PaymentUpdation
              profiles={profiles}
              profileUpdate={profileUpdate}
              setProfileUpdate={setProfileUpdate}
              setNewPayModal={setNewPayModal}
              profileData={profileData}
            />
          </Elements>
        </>
      </Modal>
      <Loader loader={loader} />
    </div>
  );
}

export default MyAccount;
