import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import fb from "../../../images/fb.svg";
import pinterest from "../../../images/pinter.svg";
import insta from "../../../images/insta.svg";
import { FadeInSection } from "../../shared/Layout/IntersectionObserver";
import { useNavigate } from "react-router-dom";
import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

export const Footer = () => {
  const navigate = useNavigate();
  const [started, setStarted] = useState();
  const [login, setLogin] = useState();
  const [sign, setSign] = useState();

  const click = () => {
    navigate("/get-started");
    window.scrollTo(0, 0);
  };
  let profileTok = localStorage.getItem("tokenn");

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "shapeShoppGetStarted",
      order: "sys.id",
    });
    setStarted(entry.items);

    const entry2 = await client.getEntries({
      content_type: "shapeshoppFooterLogin",
      order: "sys.id",
    });
    setLogin(entry2.items);

    const entry3 = await client.getEntries({
      content_type: "shapeshoppFooter",
      order: "sys.id",
    });
    setSign(entry3.items);
  }, []);

  return (
    <>
      <div className="getstarted-action-btn">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="get-started">
                {profileTok ? (
                  <>
                    {login?.map((ele, key) => (
                      <h2 key={key} className="font-lg-32">
                        {ele?.fields?.text}
                      </h2>
                    ))}
                    <div className="hero-img">
                      <img src="/images/colored_circles_for_site.png" alt="" />
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    {sign?.map((ele, key) => (
                      <h2 key={key} className="font-lg-32">
                        {ele?.fields?.texts}
                      </h2>
                    ))}
                    <div className="hero-img">
                      <img src="/images/colored_circles_for_site.png" alt="" />
                    </div>
                    {started?.map((ele, key) => (
                      <FadeInSection key={key}>
                        <button
                          key={key}
                          id="get-started landing-footer"
                          className="btn btn-lg-primary"
                          onClick={click}
                        >
                          {ele?.fields?.text}
                        </button>
                      </FadeInSection>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="menu">
                <div>
                  <Link to="/our-story">Our Story</Link>
                </div>
                <br></br>
                <div>
                  <Link to="/term">Terms of Use</Link>
                </div>
                <br></br>
                <div>
                  <a href="mailto:maria@shapeshopp.com">Contact Us</a>
                </div>
               
                <br></br>
                <div>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img className="foot-img" src="/images/ShapeShopp_lockup_BLK.svg" alt="logo" />
              <p className="text-small">
                ShapeShopp™ is a trademark of{" "}
                <a href="https://www.revshopp.com/" target="blank">
                  {" "}
                  RevShopp,{" "}
                </a>{" "}
                Inc. ©2022
              </p>
            </div>
            <div className="col-md-6">
              <div className="social-icons">
                <a href="https://www.facebook.com/TheStyleDial" target="blank">
                  <img src={fb} alt="logo" />
                </a>
                <a
                  href="https://www.pinterest.com/ShapeShopp/_saved/"
                  target="blank"
                >
                  <img src={pinterest} alt="logo" />
                </a>
                <a
                  href="https://www.instagram.com/shapeshoppbyrevshopp/"
                  target="blank"
                >
                  <img src={insta} alt="logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
