import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import "./popup.css";
const Popup = () => {
  const [show, setShow] = useState(true);

  const onSubmit = (e) => {
    setShow(false);
    Cookies.set("shapeShop", "true");
  };

  useEffect(() => {
    let myCookies = Cookies.get("shapeShop");
  
    {myCookies ?
    setShow(false) : setShow(true);
    }
  }, []);


  return (
    <>
      {show && (
        <div className="modal1">
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  onSubmit();
                }}
              ></button> */}

              <div className="modal-body">
                <h5>This Website Uses Cookies </h5>
                <p>
                We use cookies to enhance your experience. By clicking "agree", you consent to our use of cookies.  <Link to ="/privacy-policy">Read More</Link>
                </p>
              </div>
              <button
                type="button"
                className="btn btn cookies_btn"
                onClick={(e) => {
                  onSubmit();
                }}
              >
                Agree
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
