import React, { useState, useEffect } from "react";
import "./Blog.css";
import { FadeInSection } from "../../shared/Layout/IntersectionObserver";
import { createClient } from "contentful";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

export const Blog = () => {
  const [blogs, setBlogs] = useState();
  const navigate = useNavigate();

  const blogging = () => {
    navigate("/blog");
  };

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "blogs",
      order: "sys.id",
    });
    setBlogs(entry.items);
  }, []);

  return (
    <>
      <div className="blog_section_bg section-gap">
        <div className="newsletter-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="how-it-works-icon font-lg-32 blogtext">
                  Check out what our stylists have to say
                </div>
                <div className="blog-subtitle">
                  <p className="blog-heading">
                    They've highlighted some things to help you get started
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-container">
          {blogs?.slice(0, 2).map((element, key) => (
            <FadeInSection key={key}>
              <div className="row align-items-center">
                <div className="col-md-4 offset-md-2">
                  <Link to={`/blog/${element.sys.id}`}>
                    <img
                      src={
                        element?.fields?.blogFeaturedImage?.fields?.file?.url
                      }
                      className="img-fluid"
                      id={element?.fields?.blogTitle}
                      alt=""
                    />
                  </Link>
                </div>
                <div  className="col-md-6">
                  <div className="step-display-item-content blog-content">
                    <Link to={`/blog/${element?.sys?.id}`}>
                      <div className="step-label">
                        <p>{element?.fields?.dateAndTime}</p>
                      </div>
                      <h4
                        className="font-lg-20"
                        id={element?.fields?.blogTitle}
                      >
                        {element?.fields?.blogTitle}
                      </h4>
                      <p
                        className="lato-font font-lg-18"
                        id={element?.fields?.blogTitle}
                      >
                        {element?.fields?.shortDescription}
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </FadeInSection>
          ))}

          <div className="no-credit-card-need">
            <div className="row">
              <div className="col-md-6 offset-md-2">
                <div className="get-started">
                  <h2 className="font-lg-32">
                    Get more style news on our ShapeShopp<sup>TM</sup> blog!
                  </h2>
                  <FadeInSection>
                    <button
                      id="visit our blog"
                      className="btn edit-info"
                      onClick={blogging}
                    >
                      visit our blog
                    </button>
                  </FadeInSection>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
