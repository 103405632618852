import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import { useParams } from "react-router-dom";
import "./blogpage.css";
import Loader from "../Loader.js";
import ReactMarkdown from "react-markdown";
import { INLINES } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import parse from 'html-react-parser';

const client = createClient({
  environment: process.env.REACT_APP_ENVIRONMENT_NAME,
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const BlogPage = () => {
  const [blog, setBlog] = useState([]);
  const [loader, setloader] = useState(false);

  const { id } = useParams();
  useEffect(async () => {
    setloader(true);
    const entry = await client.getEntry(id);
    setBlog(entry);
    window.scrollTo(0, 0);
    setloader(false);
  }, []);

  const options = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node) => {
        return(
          `<a href="${node.content[0].value}" target="_blank">
            <img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}">
          </a>`
        )
      }
    }
  }

  return (
    <>
      <div className="heading-getstarted"></div>
      <div className="main_blog_container">
        <div className="blog-data">
          <div className="blog-title">
            <h1>{blog?.fields?.blogTitle}</h1>
          </div>
          {/* <div className="author-name">
            <p className="avatar_title">{blog.fields?.authorName} &nbsp; </p>
            <div className="date-time">
              <p> {blog.fields?.date}</p>
            </div>
            <div className="user-stories">
              <p> &nbsp; {blog.fields?.userStories}</p>
            </div>
          </div> */}
          <div className="blog-size">
          <div className="blog-image">
            {" "}
            {blog &&
            blog?.fields &&
            blog?.fields?.blogFeaturedImage &&
            blog?.fields?.blogFeaturedImage?.fields !== undefined ? (
              <img src={blog?.fields?.blogFeaturedImage?.fields?.file?.url} alt="" />
            ) : (
              ""
            )}
          </div>
          <div className="line"> <hr></hr></div>

          <div className="blogbody">
            { blog?.fields?.blogBodyNew ? (
              parse(documentToHtmlString(blog?.fields?.blogBodyNew, options))
            ) : (
              <ReactMarkdown>{blog?.fields?.blogBody}</ReactMarkdown>
            )}
          </div>
          </div>
          <div className="avtar"></div>
        </div>
        <Loader loader={loader} />
      </div>
    </>
  );
};
export default BlogPage;
