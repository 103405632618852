import React, { useEffect, useState } from "react";
import "./PricingPlans.css";
import { createClient } from "contentful";
import Modal from "react-modal";
import Loader from "../Loader.js";

Modal.setAppElement("#root");

const client = createClient({
  environment: process.env.REACT_APP_ENVIRONMENT_NAME,
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const PricingPlans = ({ setPlan, nextPageHandler }) => {
  const [keyVal, setKeyVal] = useState(15);
  const [subplans, setSubplans] = useState();
  const [plansData, setPlansData] = useState([]);
  const [loader, setloader] = useState(false);

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "shapeshoppPlans",
      order: "sys.id",
    });
    setPlansData(entry.items);
  }, []);

  const nextPage = (planid, planType, price_id, subplans, key) => {
    setPlan((prev) => {
      return {
        plantype: planType,
        planid: planid,
        price_id: price_id,
        subplans: subplans,
      };
    });
    setKeyVal(key);
    nextPageHandler();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const klaviyo = () => {
    window._klOnsite = window._klOnsite || [];
    window._klOnsite.push(["openForm", "TmabZ6"]);
  };

  const klaviyocustom = (e) => {
    window._klOnsite = window._klOnsite || [];
    window._klOnsite.push(["openForm", "UBj3dN"]);
  };
  let profileTok = localStorage.getItem("tokenn");

  return (
    <>
      <div className="container-fluid pricing_width mb-4">
        <div className="row">
          {plansData?.map((element, key) => (
            <div className="plan col-lg-4 col-md-6 hover-prop mb-3" key={key}>
              <div
                className={`plans-main random-color-${key} ${
                  key === keyVal && "active_price"
                }`}
                id={element?.fields?.id}
              >
                {key === 0 && (
                  <span className="left_pricing_tag">Our Most Loved</span>
                )}

                <h3 className="font-lg-32">
                  {element?.fields?.planType}
                </h3>

                <p className="font-lg-24">
                  {element?.fields?.monthlyPrice}
                </p>

                <ul className="package-details">
                  {element?.fields?.planList?.content?.map((ele, key) => (
                    <li key={key}>{ele?.content[0]?.value}</li>
                  ))}
                </ul>

                <div className="select-btn-wrapper">
                  <div className="select-button">
                    {(element?.fields?.planType === "Smart" && !profileTok) ? (
                      <button
                        id="Smart"
                        className="btn select plan-btn"
                        onClick={(e) =>
                          nextPage(
                            element?.fields?.id,
                            element?.fields?.planType,
                            element?.fields?.price_id,
                            subplans,
                            key
                          )
                        }
                      >
                        Select Plan
                      </button>
                    ) : (
                      ""
                    )}
                    {element?.fields?.planType === "Savvy" ? (
                      <button
                        id="Savvy"
                        className="btn select plan-btn"
                        onClick={klaviyo}
                      >
                        Select Plan
                      </button>
                    ) : (
                      ""
                    )}
                    {element?.fields?.planType === "Custom" ? (
                      <button
                        id="Custom"
                        className="btn select plan-btn"
                        onClick={klaviyocustom}
                      >
                        Select Plan
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Loader loader={loader} />
    </>
  );
};

export default PricingPlans;
