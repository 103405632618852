import React from "react";
import { FadeInSection } from "../../shared/Layout/IntersectionObserver";
import './Confirmation-Cheers.css';

export const ConfirmationCheers = () => {
  return (
    <div className="howitworks-container bg-transparent">
      <div className="container">
        <FadeInSection>
          <div className="howitworks-content">
            <h2 className="font-lg-32">
            Cheers to starting your journey! This is just the beginning of shopping your shape and loving your closet.
            </h2>
          </div>  
          <div className="color-bar">
            <div className="multi-color-section"></div>
          </div>
        </FadeInSection>
      </div>
    </div >
  );
};
