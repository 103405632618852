import React, { useState, useEffect } from "react";
import { createClient } from "contentful";


const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const Catalogs = ({ key, ele }) => {
  const [data, setData] = useState([]);
  const imagesData = localStorage.getItem("color");
  const [catinfo, setCatinfo] = useState("");

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "daysOfStyleVideos",
      order: "sys.id",
    });
    let image = entry.items.filter((x) => x?.fields?.bodyShape === imagesData  );
    let catFilter = image?.find((y)=> y?.fields?.brandName == "Your Living Cheat Sheet"  )
    let filtercat = image.filter((z)=> z?.fields?.brandName != "Your Living Cheat Sheet")
    let sortArray =filtercat.sort(function(a,b){
      return new Date(a.sys.createdAt) - new Date(b.sys.createdAt);
    });
    setData(sortArray.reverse());
    setCatinfo(catFilter);
  }, []);


  return (
    <>
      <div className="col-md-3 col-6" key={key}>
          <div className="catelog-wrapper" >
          <a target="blank" href ={catinfo?.fields?.url}  >
            <img
              className="img-fluid"
              src={catinfo?.fields?.productImage?.fields?.file?.url}
              alt=""
              id={`${catinfo?.fields?.bodyShape} ${catinfo?.fields?.brandName}`}
            />
            <h4>{catinfo?.fields?.brandName}</h4>
            <p>{catinfo?.fields?.productName}</p>
            </a>
          </div>
        </div>
      {data?.map((element, key) => (
          <div className="col-md-3 col-6" key={key}>
          <div className="catelog-wrapper">
          <a target="blank" href ={element?.fields?.url}>
            <img
              className="img-fluid"
              src={element?.fields?.productImage?.fields?.file?.url}
              alt=""
              id= {`${element?.fields?.bodyShape} ${element?.fields?.brandName}`}
            />
            <h4>{element?.fields?.brandName}</h4>
            <p>{element?.fields?.productName}</p>
            </a>
          </div>
        </div>
      ))}
    </>
  );
};

export default Catalogs;
