import React from "react";
import { Link } from "react-router-dom";
import { FadeInSection } from "../../shared/Layout/IntersectionObserver";
import { useNavigate } from "react-router-dom";
import "./Confirmation-fIve-Style.css";

export const ConfirmationfiveStyle = () => {
 const navigate= useNavigate()

 const dash=()=>{
   navigate("/login-successful")
 };

  return (
    <div className="howitworks-container streamline-container">
      <div className="container">
        <FadeInSection>
        <div className="how-it-works-icon font-lg-32">
          <div className="play-icon"></div>
          <span>Shape</span>
        </div>
        <h2 className="shape_content">
          <Link to="/login-successful">
            {" "}
            Visit your <u onClick= {dash}>dashboard</u> to see your cheat sheet and the ideal
            silhouettes for your shape
          </Link>
        </h2>
        <div className="wrapper-img">
          <Link to="/login-successful">
            <img className="confirmation-ladies" src="/images/ladies_in_black_workout_gear.webp" alt =""></img>
          </Link>
        </div>
        </FadeInSection>
      </div>
    </div>
  );
};
