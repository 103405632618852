import React, { useState } from "react";
import "./GetStarted.css";
import BodyShape from "./BodyShape";
import { useNavigate } from "react-router-dom";
import PricingPlans from "../../PricingPlans/PricingPlans";
import Form from "../../PricingPlans/Form";
import { ToastContainer, cssTransition } from "react-toastify";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";

export const GetStarted = () => {
  const [val, setVal] = useState("");
  const [plan, setPlan] = useState({
    plantype: "",
    planid: "",
    planStyle: "",
    price_id: "",
  });

  const [form, setForm] = useState("");
  const [style, setStyle] = useState("");
  const [display, setDisplay] = useState(1);
  const [color, setColor] = useState("");
  const [background, setBackground] = useState("");
  const [subplans, setSubplans] = useState("");

  const navigate = useNavigate();

  const login = () => {
    navigate("/login");
  };

  const bounce = cssTransition({
    enter: "animate__animated animate__bounceIn",
    exit: "animate__animated animate__bounceOut",
  });

  const pricing = () => {
    if (display === 1 && val === "") {
    } else {
      setDisplay(display + 1);
      window.scrollTo(0, 0);
    }
  };
  const back = () => {
    setDisplay((prev) => prev - 1);
  };

  return (
    <>
      <div className="getstarted-main">
        {display === 1 && (
          <BodyShape
            nextPageHandler={pricing()}
            setVal={setVal}
            setPlan={setPlan}
            plan={plan}
            val={val}
            setStyle={setStyle}
            setColor={setColor}
            setBackground={setBackground}
          />
        )}

        {display === 2 && (
          <div className="pricing-plan">
            <div className="container">
              <div className="heading-getstarted py-0">
                <h6 className="get">GET STARTED</h6>
                <h2 className="get-text">
                  Choose your <br /> membership level..
                </h2>
              </div>
            </div>

            <PricingPlans
              back={back}
              plan={plan}
              setPlan={setPlan}
              subplans={subplans}
              setSubplans={setSubplans}
              nextPageHandler={pricing}
            />
          </div>
        )}
        {(display === 3 || display === 4) && (
          <Form
          
            setForm={setForm}
            display={display}
            setPlan={setPlan}
            setDisplay={setDisplay}
            setStyle={setStyle}
            plan={plan}
            val={val}
            setVal={setVal}
            back={back}
            pricing={pricing}
            style={style}
            color={color}
            setColor={setColor}
            background={background}
            setBackground={setBackground}
            subplans={subplans}
            setSubplans={setSubplans}
          />
        )}

        <div className="container">
          {display === 1 || display === 2 ? (
            <div className="body_shape already-account">
              <h5>
                Already have an account? <u onClick={login}> Log in here </u>
              </h5>
              <div className="cont"></div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <ToastContainer
        transition={bounce}
        position="bottom-right"
        autoClose={0.5}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
};
