import React, { useState, useEffect } from "react";
import "./privacy.css";
import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const Privacy = () => {
const [privacy, setPrivacy] = useState();

  useEffect(async () => {
    window.scrollTo(0, 0);
    const entry = await client.getEntries({
      content_type: "privacyPolicy",
      order: "sys.id",
    });
    setPrivacy(entry?.items);
    
  }, []);


  window.scrollTo(0, 0); 
  return (
    <>
      <div className="heading-getstarted"></div>
     <div className="container- privacy_policy">
     {privacy?.map((element, key) => (
          <div key={key} className="privacy-area">
              {element?.fields?.privacyData?.content[0]?.content?.map(
                (ele, key) => (
                  <div key={key} dangerouslySetInnerHTML={{ __html: ele.value }}></div>
                )
              )}
          </div>
        ))}
     </div>    
    </>
  );
};

export default Privacy;
