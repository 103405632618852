import React, { useState, useEffect } from "react";
import "./term.css";
import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

export const Term = () => {
  const [terms, setTerms] = useState();

  useEffect(async () => {
    window.scrollTo(0, 0);
    const entry = await client.getEntries({
      content_type: "termsAndServices",
      order: "sys.id",
    });
    setTerms(entry?.items);
  }, []);



  return (
    <>
      <div className="heading-getstarted"></div>
      <div className="container TermsAndServices">
        {terms?.map((element, key) => (
          <div key={key} className="term-area">
              {element?.fields?.termsAndServices?.content[0]?.content?.map(
                (ele,key) => (
                  <div key={key} dangerouslySetInnerHTML={{ __html: ele.value }}></div>
                )
              )}
          </div>
        ))}
      </div>
    </>
  );
};

export default Term;
