import React, { useEffect, useState } from "react";
import "./our-story.css";
import "./OurStory.css";
import { createClient } from "contentful";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const OurStory = () => {
  const [story, setStory] = useState();
  const [section4, setSection4] = useState();
  const [cards, setCards] = useState();

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "ourStory",
      order: "sys.id",
    });
    setStory(entry?.items);

    const entry2 = await client.getEntries({
      content_type: "shapeshoppSection4",
      order: "sys.id",
    });
    setSection4(entry2.items);

    const entry3 = await client.getEntries({
      content_type: "ourStoryCards",
      order: "sys.id",
    });
    setCards(entry3?.items);
  }, []);

  window.scrollTo(0,0);
  return (
    <>
      <div className="story-our story">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 flex-center story__hero__content-block">
              {story?.map((element, key) => (
                <div key={key} className="story__hero__heading">
                  <h6 className="get">
                    {element?.fields?.heading}
                  </h6>
                  <h2 className="get-text">
                    {element?.fields?.storyArea}
                  </h2>
                </div>
              ))}
            </div>
            {story?.map((element, key) => (
              <div key={key} className="col-lg-6 story__hero__image-block">
                <img
                  className="story__hero__image"
                  src={element?.fields?.storyImage?.fields?.file?.url}
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
        <div className="container-fluid">
          <div className="text-story">
            {story?.map((element, key) => (
              <div key={key} className="story-area">
                {element?.fields?.text?.content[0]?.content?.map((ele,key) => (
                  <div key={key} dangerouslySetInnerHTML={{ __html: ele.value }}></div>
                ))}
              </div>
            ))}
          </div>
          <div className="story-card">
            {cards?.map((ele, key) => (
              <Card key={key}>
                <Card.Body >
                  <div className="storycard-img-1">
                    <Card.Img variant="top" src="/images/Quotation.png" />
                  </div>
                  <Card.Text>{ele?.fields?.comments}</Card.Text>
                  <div className="img-text"></div>
                  <div className="storycard-img">
                    <Card.Img
                      variant="bottom"
                      src={ele?.fields?.cardImage?.fields?.file?.url}
                    />
                    <p>{ele?.fields?.name}</p>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>
          <div className="container">
        <div className="howitworks-content">
         {section4?.map((ele, key)=>
         <h2 key={key} className="font-lg-32">
           <div dangerouslySetInnerHTML={{ __html: ele?.fields?.text }}></div>
         </h2>
       )}
       </div>

          <div className="color-bar">
            <div className="multi-color-section"></div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default OurStory;
