import React, { useEffect, useState, useContext } from "react";
import hamburger from "../../../images/hamburger.png";
import "./NavBar.css";
import { useNavigate, useLocation } from "react-router-dom";
import Profilecontext from "../../../Context/Profilecontext";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import axios from "axios";
import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

export const NavBar = () => {
  const { profilepic, setProfilepic, navset, setNavset } = useContext(Profilecontext);

  const navigate = useNavigate();

  const login = () => {
    navigate("/login");
  };
  const MyAccount = () => {
    navigate("/settings");
  };

  const dash = () => {
    navigate("/login-successful");
  };
  const [showlogin, setShowlogin] = useState(true);
  const [picture, setPicture] = useState("");
  const [navtext, setNavtext] = useState();
  const [loader, setloader] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname.includes("/login")) {
      setShowlogin(false);
    } else {
      setShowlogin(true);
    }
  }, []);

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "shapeshoppNavigation",
      order: "sys.id",
    });

    setNavtext(entry.items);
  }, []);

  const [confirmation, setConfirmation] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [navbarBg, setNavbarBg] = useState("");

  const fromUserValue = {
    profile_pic: "",
  };

  //Bg scroll function
  const changeBg = () => {
    if (window.scrollY >= 25) {
      setNavbarBg("white");
    } else {
      setNavbarBg("");
    }
  };

  useEffect(() => {
    if (location.pathname.includes("confirmation")) {
      setConfirmation(true);
      localStorage.getItem("tokenn");
    } else {
      setConfirmation(false);
    }
    changeBg();
    window.addEventListener("scroll", changeBg);
  });

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenn");
    localStorage.removeItem("date");
    localStorage.removeItem("color");
    setProfilepic("");
    navigate("/login");
  };

  let profileTok = localStorage.getItem("tokenn");
  useEffect(() => {
    if (profileTok) {
      profileData();}
  });

  const profileData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/v1/users/get_profile`, {
        headers: {
          Authorization: `Bearer ${profileTok}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setProfilepic(response?.data?.user?.profile_pic);
      });
  };
  const onChangePicture = async (e) => {
    setPicture(e.target.files[0]);
    // e.preventDefault();
    setloader(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${profileTok}`);

    var formdata = new FormData();

    formdata.append("user[profile_pic]", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/users/update_profile`,
      requestOptions
    )
      .then((response) => response.text())

      .then((result) => {

        profileData();
        setloader(false);
      })
      .catch((error) => {
        setloader(false);
        console.log("error", error);
      });
  };

  return (
    <div
      className={`${"navbar-container"} ${
        navbarBg
          ? "nav-white"
          : confirmation === true
          ? "nav-white"
          : "nav-default"
      } ${ navset == true? "opacity_module": ""} ` }

    >
      <Link to="/">
        <img
          src="/images/ShapeShopp_lockup_BLK.svg"
          alt=""
          width="268px"
        />
      </Link>
      <div className="pc navbar-submenu">
        <div>
          <Link to="/pricing-plans">plans</Link>
        </div>

        {profileTok ? "" : (<> {navtext?.map((ele, key) => (
          <div key={key}>
            <Link to="/get-started" id="get-started landing-navbar">
              {ele?.fields?.navMenu}
            </Link>
          </div>
        ))}</>) }


        <div>
          <Link to="/blog">blog</Link>
        </div>

        <div>
          <Link to="/our-story">our story</Link>
        </div>
        {profileTok?  <div>
          <Link to="/login-successful">dashboard</Link>
        </div>:""}

      </div>
      {localStorage.getItem("tokenn") &&
      localStorage.getItem("tokenn") !== "" ? (
        <Dropdown>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="btn-style"
          >
            {profilepic === undefined ||
            profilepic === null ||
            profilepic === "" ? (
              <>
                <i className="fa fa-user icon" />
                <div className="edit-pic">
                  <input
                    className="change-profile-file up-nav"
                    type="file"
                    onChange={onChangePicture}
                  />
                  <i className="fa fa-edit icon"></i>
                </div>
              </>
            ) : (
              <img src={profilepic} alt="" className="sdf" height={"30px"} />
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={MyAccount}>My Account</Dropdown.Item>
            <Dropdown.Item onClick={logout}>Log out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <div className="pc navbar-login">
          <button className="btn-lg-secondary logindetails" onClick={login}>
            log in
          </button>
        </div>
      )}

      <div className="mobile hamburger" onClick={() => setShowNav(true)}>
        <img src={hamburger} alt="" />
      </div>
      <div
        className={`${
          showNav && "navbar-container-mobile-shownav"
        } navbar-container-mobile`}
        onClick={() => setShowNav(false)}
      >
        <div className="navbar-container-mobile-inner">
          <div className="mobile-logo">
            <img src="/images/ShapeShopp_lockup_BLK.svg" alt="" />
          </div>
          <br />
          <div className="navbar-mobile-submenu">
            <div>
              <Link to="/pricing-plans">plans</Link>
            </div>
            {profileTok ? "" : (<> {navtext?.map((ele, key) => (
          <div key={key}>
            <Link to="/get-started" id="get-started landing-navbar">
              {ele?.fields?.navMenu}
            </Link>
          </div>
        ))}</>) }
            <div>
              <Link to="/blog">blog</Link>
            </div>

            <div>
              <Link to="/our-story">our story</Link>
            </div>
            {profileTok?  <div>
          <Link to="/login-successful">dashboard</Link>
        </div>:""}
            {localStorage.getItem("tokenn") &&
            localStorage.getItem("tokenn") !== "" ? (
              <Dropdown>
                <Dropdown.Menu>
                  {/* <Dropdown.Item onClick={dash}>Dashboard</Dropdown.Item> */}
                  <Dropdown.Item onClick={MyAccount}>My Account</Dropdown.Item>
                  <Dropdown.Item onClick={logout}>Log out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <div className="p-0 navbar-login">
                <button
                  className="btn-lg-secondary logindetails"
                  onClick={login}
                >
                  log in
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
