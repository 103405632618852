import Profilecontext from "./Profilecontext"; 
import React, {useState} from 'react';

const Profilestate = (props) => {
    const [profilepic, setProfilepic] =useState("");
    const [navset, setNavset] =useState(false);
    

    return (
        <Profilecontext.Provider value={{ profilepic, setProfilepic,navset, setNavset}}>
            {props.children}
        </Profilecontext.Provider>
    )
}
export default Profilestate;