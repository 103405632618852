import React, { useEffect, useState, useRef, useContext } from "react";
import "./Form.css";
import data from "../Home/GetStarted/body.json";
import Payment from "./Payment";
import axios from "axios";
import Modal from "react-modal";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckIcon from "@mui/icons-material/Check";
import Input from "@material-ui/core/Input";
import validator from "validator";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { ToastContainer, cssTransition } from "react-toastify";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { createClient } from "contentful";
import { Link } from "react-router-dom";
import Loader from "../Loader.js";
import Profilecontext from "../../Context/Profilecontext";

Modal.setAppElement("#root");

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const Form = (props) => {
  const { navset, setNavset } = useContext(Profilecontext);
  const month = [
    { days: 31, name: "January" },
    { days: 28, name: "February" },
    { days: 31, name: "March" },
    { days: 30, name: "April" },
    { days: 31, name: "May" },
    { days: 30, name: "June" },
    { days: 31, name: "July" },
    { days: 31, name: "August" },
    { days: 30, name: "September" },
    { days: 31, name: "October" },
    { days: 30, name: "November" },
    { days: 31, name: "December" },
  ];
  const range = (min, max) =>
    Array.from({ length: max - min + 1 }, (_, i) => min + i);
  const [powerDay, setPowerDay] = useState(31);
  const [image, setImage] = useState();
  const [list, setList] = useState();
  const [display, setDisplay] = useState(3);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordHint, setPasswordHint] = useState(false);
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters
  const [disableButton, setDisableButton] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [height, setHeight] = useState();
  const [sizes, setSizes] = useState();
  const [goforward, setgoforward] = useState();
  const [custID, setCustID] = useState("");
  const [tokens, setTokens] = useState("");
  const [plansData, setPlansData] = useState();
  const [planID, setPlanID] = useState();
  const [loader, setloader] = useState(false);
  const [phones, setPhones] = useState(false);
  const [emails, setEmails] = useState(false);
  const [promoValid, setPromoValid] = useState(false);
  const [sucssessBoolean, setSucssessBoolean] = useState(false);
  const [fullyFreePromo, setFullyFreePromo] = useState(false);
  const [failBoolean, setFailBoolean] = useState(false);
  const [verify, setVerify] = useState();
  const [checkingPromo, setCheckingPromo] = useState(null);
  const [payloadval, setPayloadVal] = useState("");
  const [emailBorder, setEmailborder] = useState(false);
  const [firstVal, setFirstVal] = useState(false);
  const [lastVal, setLastVal] = useState(false);
  const [phoneVal, setPhoneVal] = useState(false);
  const [zipVal, setZipVal] = useState(false);
  const [passVal, setpassVal] = useState(false);
  const promoButton = useRef(null);

  const [store, setStore] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    country_code: "+1",
    birthday: "",
    password: "",
    zip_code: "",
    body_shape_color: props.color,
    // plan_id: "prod_ME4H1QGQGxxiRJ",
    plan_id: props.plan.planid,
    stripe_customer_id: "",
    promo_code: "",
  });
  const [birthdayvalue, setBirthdayvalue] = useState({
    day: "",
    month: "",
    year: "",
  });

  const validateEmail = (e) => {
    setEmailborder(false);
    var email = e.target.value.toLowerCase();
    setStore((store) => {
      return {
        ...store,
        email: email,
      };
    });
    if (validator.isEmail(email)) {
      setEmailError("");
      setEmailborder(false);
    } else {
      setEmailError("Please enter a valid email address ");
    }
  };
  const mustContainData = [
    ["an uppercase letter (A-Z)", containsUL],
    ["a lowercase letter (a-z)", containsLL],
    ["a number (0-9)", containsN],
    ["a special character (!@#$)", containsSC],
    ["at least 8 characters", contains8C],
  ];

  const validatePassword = (event) => {
    setpassVal(false);
    let passwordOne = event.target.value;
    setStore((prev) => {
      return {
        ...prev,
        password: passwordOne,
      };
    });
    setPassword(passwordOne);
    setPasswordHint(true);
    let temp_all_valid = true;
    setDisableButton(false);
    // has uppercase letter
    if (passwordOne.toLowerCase() !== passwordOne) {
      setContainsUL(true);
    } else {
      setContainsUL(false);
      temp_all_valid = false;
    }

    // has lowercase letter
    if (passwordOne.toUpperCase() !== passwordOne) {
      setContainsLL(true);
    } else {
      setContainsLL(false);
      temp_all_valid = false;
    }
    // has number
    if (/\d/.test(passwordOne)) {
      setContainsN(true);
    } else {
      setContainsN(false);
      temp_all_valid = false;
    }
    // has special character
    if (/[~`!#@$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(passwordOne)) {
      setContainsSC(true);
    } else {
      setContainsSC(false);
      temp_all_valid = false;
    }
    // has 8 characters
    if (passwordOne.length >= 8) {
      setContains8C(true);
    } else {
      setContains8C(false);
      temp_all_valid = false;
    }
    // all validations passed
    if (temp_all_valid) {
      setPasswordHint(false);
      setDisableButton(true);
    }
  };

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();
  const login = () => {
    navigate("/login");
  };

  const [id, setId] = useState();

  // API FOR GET SIZE CATEGORIES--

  const fetchsize = async (e) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/v1/size_categories`
    );

    setHeight([...response.data.heights].reverse());

    setSizes([...response.data.sizes]);
  };

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "shapeshoppPlans",
      order: "sys.id",
    });
    setPlansData(entry.items);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [plansData]);

  useEffect(() => {
    plansData?.filter((dat) => {
      if (dat?.fields?.planType === props.plan.plantype) {
        setList(dat);
      }
    });
    fetchsize();
    setHeight();
  }, [props.plan, plansData]);

  const [selected, setSelected] = useState();
  const handleSelected = (item) => {
    setSelected(item.id);
  };

  const [size, setSize] = useState("");
  const handleSelectedSize = (item) => {
    setSize(item.id);
  };

  useEffect(() => {
    data.filter((dat) => {
      if (dat.id === props.val) {
        setImage(dat);
      }
    });
    window.scrollTo(0, 0);
  }, [props.val]);

  const handleClick = (value, key) => {
    props.setBackground(value.background);
    setImage(value.selected_image);
    props.setColor(value.color);
    props.setVal(value.id);
    props.setStyle(value.style_number);
    setStore((prev) => {
      return {
        ...prev,
        style_number: value.style_number,
        body_shape_color: value.color,
      };
    });
    setModalIsOpen(false);
  };

  // creting promocode and changing button with condition
  const [promosuccess, setPromosuccess] = useState({
    msg: null,
    caption: null
  });

  const [promoErr, setPromoErr] = useState({
    msg: null,
    caption: null
  });

  const [promoAmt, setPromoAmt] = useState({
    promo_code: "",
  });
  const bounce = cssTransition({
    enter: "animate__animated animate__bounceIn",
    exit: "animate__animated animate__bounceOut",
  });

  // API FOR PROMO CODE---

  const getPromoCaption = (promoCode) => {
    if (promoCode.includes("50")) {
      return "50% off";
    }
    else {
      return "FREE";
    }

  }

  const isFullyFreePromo = (promoCode) => {
    if (getPromoCaption(promoCode) == "FREE") {
      return true;
    }
    else {
      return false;
    }
  }

  const getPromo = async (promommt) => {
    // e.preventDefault();
    setCheckingPromo(true);
    const res = axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/v1/subscriptions/verify_promo_code`,
        { promo_code: promommt, plan_id: props.plan.planid }
      )
      .then((res) => {
        setVerify(res?.data?.verified);
        setFailBoolean(false);
        if (res?.data?.verified) {
          promoButton.current.click();
          setSucssessBoolean(true);
          setFailBoolean(false);
          setFullyFreePromo(isFullyFreePromo(promommt));

          setPromosuccess((success) => {
            setCheckingPromo(false);
            return {
              ...success,
              msg: "Promo code added",
              caption: getPromoCaption(promommt)
            };
          });
          setPromoErr((err) => {
            return {
              ...err,
              msg: null,
              caption: null
            };
          });
        } else {
          setFailBoolean(true);
          setCheckingPromo(null);
          setPromoErr((err) => {
            setCheckingPromo(false);
            return {
              ...err,
              msg: "Invalid code",
              caption: null
            };
          });
          setPromosuccess((success) => {
            return {
              ...success,
              msg: null,
              caption: getPromoCaption(promommt)
            };
          });
        }
      });
  };

  const setDOB = (valueyear) => {
    setStore((birthadyValue) => {
      return {
        ...birthadyValue,
        birthday: `${birthdayvalue["day"]}/${birthdayvalue["month"]}/${valueyear}`,
      };
    });
  };

  // API FOR REGISTER USER---

  const handleSubmit = async (e) => {
    let payload = {
      first_name: store.first_name,
      last_name: store.last_name,
      phone: store.phone.slice(1),
      email: store.email,
      country_code: "+1",
      birthday: store.birthday,
      password: store.password,
      zip_code: store.zip_code,
      body_shape_color: props.color,
      // plan_id: "prod_ME4H1QGQGxxiRJ",
      plan_id: props.plan.planid,
      stripe_customer_id: "",
    };
    try {
      e.preventDefault();
      if (sucssessBoolean === true && fullyFreePromo == true) {
        setloader(true);
        setgoforward(false);
        let customer_id = null;
        let plann_id = null;
        let tokens = null;
        let form_data = new FormData();
        form_data.append("user[first_name]", store.first_name);
        form_data.append("user[last_name]", store.last_name);
        form_data.append("user[email]", store.email);
        form_data.append("user[phone]", store.phone.slice(1));
        form_data.append("user[country_code]", "+1");
        form_data.append("user[body_shape_color]", props.color);
        form_data.append("user[plan_id]", props.plan.planid);
        form_data.append("user[zip_code]", store.zip_code);
        form_data.append("user[size_ids][]", size);
        form_data.append("user[height_ids][]", selected);
        form_data.append("user[birthday]", store.birthday);
        form_data.append("promo_code", promoAmt.promo_code);
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_BASE_URL}/v1/klaviyos`,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
          data: form_data,
        };

        await axios(config).then((response) => {
          if (response?.data?.phone_no_already_taken === true) {
            setloader(false);
            setPhones(true);
            setgoforward(true);
          }

          if (response?.data?.email_already_taken === true) {
            setloader(false);
            setEmails(true);
            setgoforward(true);
          }
          if (response?.data?.promo_code_verified === false) {
            setloader(false);
            setPromoValid(true);
            setgoforward(true);
          } else {
            setStore((prev) => {
              return {
                ...prev,
                stripe_customer_id: response?.data?.stripe_customer_id,
              };
            });
            payload.stripe_customer_id = response?.data?.stripe_customer_id;
          }
        });

        await axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/v1/users`,
            {
              user: payload,
              height_ids: selected == null ? [] : [selected],
              size_ids: size == "" ? [] : [size],
            },
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            customer_id = response?.data?.user?.stripe_customer_id;
            setId(response.data.id);
            localStorage.setItem("tokenn", response?.data.token);
            localStorage.setItem("date", response?.data.user.created_at);
            localStorage.setItem("color", response?.data.user.body_shape_color);
            localStorage.setItem("firstTimeSignUp", true);
            tokens = response?.data?.token;
            setTokens(response?.data?.token);
            plann_id = response?.data?.user?.plan?.id;
          });

        //  CREATE SUBSCRIPTION API

        await axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/v1/subscriptions`,
            {
              subscription: {
                stripe_customer_id: customer_id,
                // price_id: "price_1LjmMXEO6Kb9LR1SWRJAGYHk",
                price_id: props.plan.price_id,
                promo_code: promoAmt.promo_code,
                promo_code_applied: "true",
                // plan_id: "prod_ME4H1QGQGxxiRJ",
                plan_id: props.plan.planid,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${tokens}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((res) => {
            navigate("/login-successful");
            setloader(false);
          });
      } else {
        setloader(true);
        setgoforward(false);
        let form_data = new FormData();
        form_data.append("user[first_name]", store.first_name);
        form_data.append("user[last_name]", store.last_name);
        form_data.append("user[email]", store.email);
        form_data.append("user[phone]", store.phone.slice(1));
        form_data.append("user[country_code]", "+1");
        form_data.append("user[body_shape_color]", props.color);
        form_data.append("user[plan_id]", props.plan.planid);
        form_data.append("user[zip_code]", store.zip_code);
        form_data.append("user[size_ids][]", size);
        form_data.append("user[height_ids][]", selected);
        form_data.append("user[birthday]", store.birthday);
        form_data.append("promo_code", promoAmt.promo_code);
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_BASE_URL}/v1/klaviyos`,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
          data: form_data,
        };
        await axios(config).then((response) => {
          if (response?.data?.phone_no_already_taken === true) {
            setloader(false);
            setPhones(true);
            setgoforward(true);
          }
          if (response?.data?.email_already_taken === true) {
            setloader(false);
            setEmails(true);
            setgoforward(true);
          } else {
            setCustID(response?.data?.stripe_customer_id);
            setStore((prev) => {
              return {
                ...prev,
                stripe_customer_id: response?.data?.stripe_customer_id,
              };
            });
            setId(response.data.id);
            setTokens(response?.data?.token);
            setPlanID(response?.data?.user?.plan?.id);
            let payloads = {
              first_name: store.first_name,
              last_name: store.last_name,
              phone: store.phone.slice(1),
              email: store.email,
              country_code: "+1",
              birthday: store.birthday,
              password: store.password,
              zip_code: store.zip_code,
              body_shape_color: props.color,
              // plan_id: "prod_ME4H1QGQGxxiRJ",
              plan_id: props.plan.planid,
              stripe_customer_id: response?.data?.stripe_customer_id,
            };
            setPayloadVal(payloads);
            props.setDisplay(display + 1);
            setloader(false);
            setgoforward(true);
          }
        });
      }
    } catch (error) {
      setloader(false);
      setgoforward(true);
    }
  };

  const handleValues = (e) => {
    // if(store.first_name !== ""){
    //   setFirstVal(false)

    // }
    // else(setFirstVal(true));

    // if(store.email !== ""){
    //   setEmailborder(false)

    // }
    // else(setEmailborder(true));

    // if(store.phone !== ""){
    //   setPhoneVal(false)

    // }
    // else(setPhoneVal(true));

    // if(store.zip_code !== ""){
    //   setZipVal(false)

    // }
    // else(setZipVal(true));

    // if(store.last_name !== ""){
    //   setLastVal(false)

    // }
    // else(setLastVal(true));

    const value = e.target.value;
    const key = e.target.name;
    setStore((oldVal) => ({
      ...oldVal,
      [key]: value,
    }));
  };

  const handleNumber = (phoneNum) => {
    setStore((prev) => {
      return {
        ...prev,
        phone: phoneNum,
      };
    });
  };

  const promocheck = (e) => {
    const value = e.target.value.toUpperCase();
    const key = e.target.name;

    setPromoAmt((oldVal) => {
      return {
        ...oldVal,
        [key]: value,
      };
    });
    getPromo(value);
  };

  const remove = () => {
    setSucssessBoolean(false);
    setFailBoolean(false);
    setVerify(false);
    setPromosuccess((success) => {
      return {
        ...success,
        msg: null,
      };
    });
    setPromoErr((err) => {
      return {
        ...err,
        msg: null,
      };
    });
    setPromoAmt((element) => {
      return {
        ...element,
        promo_code: "",
      };
    });
  };

  const Monthday = (value) => {
    setPowerDay(parseInt(value));
  };

  const getMonth = (value) => {
    var select = document.getElementById("Month");
    setBirthdayvalue({
      ...birthdayvalue,
      month: moment()
        .month(select.options[select.selectedIndex].text)
        .format("M"),
      day: value,
    });
  };
  const handleChange = () => {
    if (store.first_name === "") {
      setFirstVal(true);
    }
    //  else setFirstVal(false);

    if (store.email === "") {
      setEmailborder(true);
    }
    //  else setEmailborder(false);

    if (store.phone.length < 10) {
      setPhoneVal(true);
    } 
    // else setPhoneVal(false);
    if (store.zip_code.length < 5) {
      setZipVal(true);
    }
    //  else setZipVal(false);

    if (store.last_name === "") {
      setLastVal(true);
    } 
    // else setLastVal(false);/

    if (store.password === "") {
      setpassVal(true);
    }
    //  else setpassVal(false);

    goforward === true ? setgoforward(false) : setgoforward(true);
  };
console.log("code", store.zip_code.length)
  return (
    <>
      <div className="container">
        <div className="body-shape-comp payment-form">
          <div className="form-heading">
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-9">
                {props.display === 3 && (
                  <div className="top">
                    <h6 className="get">SIGN UP</h6>
                    <h2 className="font-lg-42">You're on your way to style!</h2>
                  </div>
                )}
                {props.display === 4 && (
                  <div className="top">
                    <h2 className="font-lg-24">Enter Payment details</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid form_bg">
        <div className="container form_inner">
          <div className="row">
            <div className="form-layout">
              {props.display === 3 && (
                <form onSubmit={handleSubmit}>
                  <div className="form-group form_email">
                    <label>Email Address*</label>
                    <>
                      {" "}
                      <input
                        label="Email-Address"
                        name="email"
                        type="text"
                        autocomplete="off"
                        className={
                          emailError ? "form-control red-alert" : "form-control"
                        }
                        id="userEmail"
                        onChange={(e) => validateEmail(e)}
                        value={store.email}
                        required
                      />{" "}
                      {emails ? (
                        <div className="phone-area">
                          Email has already been taken
                        </div>
                      ) : (
                        <></>
                      )}
                      {emailBorder ? (
                        <div className="email-validation">
                          Please enter your email
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                    <span style={{ color: "red" }}>{emailError}</span>
                  </div>
                  <div className="form-group">
                    <label>Create Password*</label>
                    <Input
                      name="password"
                      type={values.showPassword ? "text" : "password"}
                      className="form-control"
                      id="password"
                      onChange={(e) => validatePassword(e)}
                      value={store.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      required
                    />
                    {passVal ? (
                      <div className="first-validation">
                        {" "}
                        Please enter a password
                      </div>
                    ) : (
                      ""
                    )}
                    {passwordHint === true ? (
                      <div className="password-hint-sec">
                        {mustContainData.map((item, key) => {
                          if (item[1] === false) {
                            return (
                              <div className="password-hint-wrapper" key={key}>
                                <div>
                                  <div className="mr-2">
                                    <ClearIcon className="alert-red" />
                                  </div>
                                </div>
                                <div style={{ paddingLeft: 5 }}>
                                  <p className="mb-0" style={{ fontSize: 14 }}>
                                    Must contain {item[0]}
                                  </p>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="password-hint-wrapper" key={key}>
                                <div>
                                  <div className="mr-2">
                                    <CheckIcon className="alert-green" />
                                  </div>
                                </div>
                                <div style={{ paddingLeft: 5 }}>
                                  <p
                                    className="mb-0"
                                    style={{ fontSize: 14 }}
                                    promo
                                  >
                                    Must contain {item[0]}
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="first_last_field">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1"></label>
                      <label>First Name*</label>
                      <input
                        name="first_name"
                        type="text"
                        autocomplete="off"
                        className="form-control"
                        id="firstname"
                        onChange={(e) => {
                          handleValues(e);
                          setFirstVal(false);
                        }}
                        value={store.first_name}
                      />
                      {firstVal ? (
                        <div className="first-validation">
                          Please enter your first name
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Last Name*</label>
                      <input
                        name="last_name"
                        type="text"
                        autocomplete="off"
                        className="form-control"
                        id="lastname"
                        onChange={(e) => {
                          handleValues(e);
                          setLastVal(false);
                        }}
                        value={store.last_name}
                        required
                      />
                      {lastVal ? (
                        <div className="first-validation">
                          Please enter your last name
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="first_last_field">
                    <div className="form-group">
                      <label>Phone*</label>
                      <PhoneInput
                        className="form-control"
                        name="phone"
                        autocomplete="off"
                        inputProps={{
                          required: true,
                          autoFocus: false,
                        }}
                        countryCodeEditable={false}
                        disableDropdown={true}
                        country={"us"}
                        onChange={(phone) => {
                          handleNumber(phone);
                          {
                            phone.length < 11
                              ? setPhoneVal(true)
                              : setPhoneVal(false);
                          }
                        }}
                        value={store.phone}
                        required
                      />
                      {phoneVal ? (
                        <div className="first-validation">
                          Please enter your number
                        </div>
                      ) : (
                        <></>
                      )}
                      {phones ? (
                        <div className="phone-area">
                          This number is already taken
                        </div>
                      ) : (
                        <></>
                      )}{" "}
                    </div>
                    <div className="form-group">
                      <label>Zip Code*</label>
                      <input
                        name="zip_code"
                        autocomplete="off"
                        className="form-control"
                        id="number"
                        onChange={(e) => {
                          handleValues(e);
  console.log("zip", e.target.value.length);

                          {
                            e.target.value.length < 5
                              ? setZipVal(true)
                              : setZipVal(false);
                          }
                        }}
                        value={store.zipcode}
                        required
                        maxLength="5"
                        minLength="5"
                      />
                      {zipVal ? (
                        <div className="first-validation">
                          Please enter your zip code
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="first_last_field">
                    <div className="form-group birthday_field">
                      <label>Birthday</label>
                      <div className="date-format">
                        <select
                          id="Month"
                          className="form-control"
                          name="month"
                          onChange={(e) => {
                            Monthday(e.target.value);
                          }}
                        >
                          <option disabled selected>
                            Month
                          </option>
                          {month.map((ele, key) => (
                            <option key={key} value={ele.days}>
                              {ele.name}
                            </option>
                          ))}
                        </select>
                        <select
                          id="day"
                          className="form-control"
                          name="Day"
                          onChange={(e) => {
                            Monthday(e.target.value);

                            getMonth(e.target.value);
                          }}
                        >
                          <option disabled selected>
                            Day
                          </option>
                          {range(1, powerDay).map((ele, key) => (
                            <option key={key} value={ele}>
                              {ele}
                            </option>
                          ))}
                        </select>
                        <select
                          id="year"
                          className="form-control"
                          name="Year"
                          onChange={(e) => {
                            Monthday(e.target.value);
                            setBirthdayvalue({
                              ...birthdayvalue,
                              year: e.target.value,
                            });
                            setDOB(e.target.value);
                          }}
                        >
                          <option disabled selected>
                            Year
                          </option>
                          {range(1947, 2022).map((ele, key) => (
                            <option key={key} value={ele}>
                              {ele}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="height-toggle">
                    <label htmlFor="exampleFormControlSelect1">Height</label>
                    <div className="form-group  size">
                      {height?.map((item, key) => (
                        <button
                          key={key}
                          type="button"
                          id={item?.name}
                          className={
                            selected === item.id
                              ? "bg-orange btn-lg-secondary-outline"
                              : "bg-black btn-lg-secondary-outline"
                          }
                          // key={key}
                          onClick={(e) => {
                            handleSelected(item);
                          }}
                        >
                          {" "}
                          {item?.name}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="size-toggle">
                    <label htmlFor="exampleFormControlSelect2">Size</label>
                    <div className="form-group  size">
                      {sizes?.map((item, key) => (
                        <button
                          key={key}
                          type="button"
                          id={item?.name}
                          className={
                            size === item.id
                              ? "bg-orange btn-lg-secondary-outline"
                              : "bg-blue btn-lg-secondary-outline"
                          }
                          // key={key}
                          onClick={(e) => handleSelectedSize(item)}
                        >
                          {" "}
                          {item?.name}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="form-group-promo">
                    <div className="promo_label">
                      <label>Promo Code</label>
                      <div className="promo-tag">
                        {sucssessBoolean ? (
                          <i className="fa fa-tag icon" />
                        ) : null}
                        {sucssessBoolean ? (
                          <p className="remove py-2" onClick={remove}>
                            Remove
                          </p>
                        ) : null}
                        {failBoolean ? (
                          <p className="remove py-2" onClick={remove}>
                            Remove
                          </p>
                        ) : (
                          ""
                        )}
                        <input
                          name="promo_code"
                          type="text"
                          className={
                            promoErr.msg == null
                              ? "form-control "
                              : "form-control red-alert"
                          }
                          onChange={(e) => promocheck(e)}
                          value={promoAmt?.promo_code}
                        />

                        {failBoolean ? (
                          <>
                            <div className="phone-area">Invalid Promo Code</div>
                          </>
                        ) : null}
                      </div>

                      {sucssessBoolean ? (
                        <span className="validation-text">
                          {promosuccess?.msg}
                        </span>
                      ) : null}

                      {promoValid ? (
                        <span className="phone-area">
                          <p>This promo code is not valid for selected plan</p>
                        </span>
                      ) : null}
                    </div>

                    <button
                      id="promocode_apply"
                      type="button"
                      ref={promoButton}
                      style={{ display: "none" }}
                    >
                      Apply
                    </button>
                  </div>
                  <div className="form-group">
                    <div className="form-control Plan-info">
                      {sucssessBoolean ? (
                        <div className="Free-plan">
                          <s className="plan-month">
                            {list?.fields?.monthlyPrice}
                          </s>{" "}
                          <p className="free">{ promosuccess?.caption }</p>{" "}
                        </div>
                      ) : (
                        <p className="plan-month">
                          {list?.fields?.monthlyPrice}{" "}
                        </p>
                      )}
                      <p className="plan-Name">
                        {list?.fields?.planType} Plan{" "}
                      </p>
                    </div>
                  </div>
                  <div className="terms form">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={handleChange}
                    />
                    <p>
                      &nbsp; I have read and agree to the &nbsp;
                      <Link to="/privacy-policy" target="blank">
                        <u>Privacy Policy</u>
                      </Link>{" "}
                      and &nbsp;
                      <Link to="/term" target="blank">
                        <u>Terms of Service</u>
                      </Link>{" "}
                    </p>
                  </div>
                  {props.display === 3 && (
                    <div className="action-btn">
                      <button
                        id="continue to payment"
                        type="submit"
                        className="btn-lg-secondary-outline"
                        disabled={
                          store.first_name === "" ||
                          store.last_name === "" ||
                          store.phone === "" ||
                          store.phone.length < 11 ||
                          store.country_code === "" ||
                          store.email === "" ||
                          // store.birthday === "" ||
                          store.password === "" ||
                          store.zip_code === "" ||
                          store.zip_code.length < 5 ||
                          store.body_shape_color === "" ||
                          store.subcription_plan === "" ||
                          // selected === "" ||
                          // size === "" ||
                          !goforward
                        }
                      >
                        {sucssessBoolean === true
                          ? "Submit"
                          : "Continue to Payment"}
                      </button>
                    </div>
                  )}
                </form>
              )}
              {props.display === 4 && (
                <Payment
                  display={props.display}
                  userID={id}
                  plan={props.plan}
                  color={props.color}
                  stylenumber={props.style}
                  background={props.background}
                  custID={custID}
                  setCustID={setCustID}
                  tokens={tokens}
                  planID={planID}
                  list={list}
                  store={store}
                  setStore={setStore}
                  selected={selected}
                  setSelected={setSelected}
                  size={size}
                  setSize={setSize}
                  payloadval={payloadval}
                  setPayloadVal={setPayloadVal}
                  promoCode={promoAmt?.promo_code}
                />
              )}
              <div className="action-btn"></div>

              <div className="body_shape already-account">
                <h6>
                  Already have an account? <u onClick={login}> Sign in </u>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Loader loader={loader} />

      <ToastContainer
        transition={bounce}
        position="bottom-right"
        autoClose={0.5}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
};

export default Form;
