import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import "./blogg.css";

const client = createClient({
  environment: process.env.REACT_APP_ENVIRONMENT_NAME,
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const Blogg = () => {
  const [blogg, setBlogg] = useState([]);
  const [singleblog, setSingleblog] = useState("");

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "blogs",
      order: "-sys.createdAt",
    });
    setSingleblog(entry.items.shift());
    setBlogg(entry.items);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="heading-getstarted"></div>
      <div className="blog container">
        {singleblog !== "" ? (
          <div className="row">
            <div className="col-sm-6 col-xl-7">
              <Link to={`/blog/${singleblog?.sys?.id}`}>
                <img
                  className="blog__hero-post__img card-img-top single"
                  src={singleblog?.fields?.blogFeaturedImage?.fields?.file?.url}
                  alt="Card cap"
                />
              </Link>
            </div>
            <div className="col-sm-6 col-xl-5">
              {/* <div className="user-time">
                <div className="user-stories">
                  <p> {singleblog?.fields?.userStories}</p>
                </div>
                <div className="single-time">{singleblog?.fields?.date}</div>
              </div> */}
              <div className="single-blogtitle mb-4">
                <Link to={`/blog/${singleblog?.sys?.id}`}>
                  {singleblog?.fields?.blogTitle}
                </Link>
              </div>
              <div className="single-text">
                {singleblog?.fields?.shortDescription}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="blog__posts row">
          {blogg?.map((element, key) => (
            <div className="col-lg-4 col-md-6" key={key}>
              <Link to={`/blog/${element?.sys?.id}`}>
                <div className="card">
                  <img
                    className="card-img-top"
                    src={element?.fields?.blogFeaturedImage?.fields?.file?.url}
                    alt="Card cap"
                    height="165px"
                  />
                  <div className="card-body">
                    <h5 className="blog__post-title">
                      {element?.fields?.blogTitle}
                    </h5>
                    <p className="blog__post-description card-text">
                      {element?.fields?.shortDescription}
                    </p>
                    {/* <div className="blog-time-user">
                      <div className="user-stories">
                        <p>{singleblog?.fields?.userStories}</p>
                      </div>
                      <div className="blogg-date-time">
                        <p>{element?.fields?.date}</p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Blogg;
