import React from "react";
import "./Tryons.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

export const Tryons = () => {
  return (
    <div className="tryons-section">
      <div className="tryons-content">
        <h1 class="font-lg-32">
          Shopping doesn't have to be this hard
        </h1>

        <div className="desc-1 mb-2">
          <span className="bolt-text">
            <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
              {({ isVisible }) => (
                <div>
                  {isVisible ? (
                    <CountUp startOnMount end={7} duration={1.5} />
                  ) : null}
                </div>
              )}
            </VisibilitySensor>
          </span>
          <div className="text">
            Number of try-ons to find one item to purchase
          </div>
        </div>
        <div className="desc-1 mb-2">
          <span className="bolt-text">
            <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
              {({ isVisible }) => (
                <div>
                  {isVisible ? (
                    <CountUp
                      startOnMount
                      end={44}
                      decimals={3}
                      decimal=","
                      duration={1.5}
                    />
                  ) : null}
                </div>
              )}
            </VisibilitySensor>
          </span>
          <div className="text">Try-ons in a lifetime</div>
        </div>
        <div className="desc-1">
          <span className="bolt-text">
            <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
              {({ isVisible }) => (
                <div>
                  {isVisible ? (
                    <CountUp startOnMount end={30} duration={1.5} suffix="%" />
                  ) : null}
                </div>
              )}
            </VisibilitySensor>
          </span>
          <div className="text">Rate of return and growing</div>
        </div>
      </div>
    </div>
  );
};
