import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import "./PaymentUpdate.css";
import Loader from "../Loader.js";

const PaymentUpdate = (props) => {
  const [updatecard, setUpdatecard] = useState(true);
  const [loader, setloader] = useState(false);

  useEffect(() => {}, [updatecard]);

  const stripe = useStripe();
  const elements = useElements();
  let profileTok = localStorage.getItem("tokenn");

  const update = async (e) => {
    setloader(true);

    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { token } = await stripe.createToken(cardElement);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/v1/users/update_card`,
        {
          token: token.id,
        },
        {
          headers: {
            Authorization: `Bearer ${profileTok}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setUpdatecard(false);
        props.setSubModal(false);
        setloader(false);
        props.profileData();
      }, [])
      .catch((error) => {  setloader(false);});
  };

  const cardStyleNumber = {
    style: {
      base: {
        color: "#575757",
        fontFamily: "poppins, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        letterSpacing: "0px",
        ":-webkit-autofill": {
          color: "#575757",
        },
        "::placeholder": {
          color: "#9C9C9C",
          fontSize: "14px",
          letterSpacing: "0.46px",
          fontWeight: "400",
          fontFamily: "poppins, sans-serif",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    showIcon: true,
    placeholder: "",
  };

  const cardStyle = {
    style: {
      base: {
        color: "#575757",
        fontFamily: "poppins, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        letterSpacing: "0px",
        ":-webkit-autofill": {
          color: "#575757",
        },
        "::placeholder": {
          color: "#9C9C9C",
          fontSize: "14px",
          letterSpacing: "0.46px",
          fontWeight: "400",
          fontFamily: "poppins, sans-serif",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    showIcon: true,
  };

  return (
    <div className="update">
      <div className="row">
        <div className="col-lg-12 mb-3">
          <h6>Card Number</h6>
          <div className="stripecard-container">
            <div className="stripe_card_number">
              <CardNumberElement
                options={cardStyleNumber}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <h6>Expiration Date</h6>
          <div className="stripe_card_expiry">
            <CardExpiryElement options={cardStyle} className="form-control" />
          </div>
        </div>
        <div className="col-lg-6">
          <h6>CVV Code</h6>
          <div className="stripe_card_cvv">
            <CardCvcElement options={cardStyle} className="form-control" />
          </div>
        </div>
      </div>
      <div className="buttons">
        <button
          type="button"
          id="card-update"
          className="btn btn-light"
          onClick={(e) => {
            update();
          }}
        >
          Update
        </button>
      </div>
      <Loader loader={loader} />
    </div>
  );
};

export default PaymentUpdate;
