import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import axios from "axios";
import Loader from "../Loader.js";

const Login = () => {
  const [loader, setloader] = useState(false);
  const [log, setLog] = useState({
    email: "",
    password: "",
  });
  const [logerror, setLogerror] = useState(false);
  const [errors, setErrors] = useState(false);

  const navigate = useNavigate();
  const register = () => {
    navigate("/get-started");
  };

  useEffect(() => { 
    window.scrollTo(0, 0);
  }, []);

  const passReset = () => {
    navigate("/reset-password");
  };

  const handleValues = (e) => {
    setLogerror(false);
    const value = e.target.value;
    const key = e.target.name;
    setLog((oldVal) => ({
      ...oldVal,
      [key]: value,
    }));
  };

  const loggedIn = async (e) => {
    setloader(true);
    e.preventDefault();
    const res = axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/v1/users/login`, {
        user: log,
      })
      .then((res) => {
        if (res?.data.user) {
          localStorage.setItem("tokenn", res.data.token);
          localStorage.setItem("date", res.data.user.created_at);
          localStorage.setItem("color", res.data.user.body_shape_color);

          navigate("/login-successful");
          setloader(false);
        }
         else {
          setloader(false);
          setLogerror(true);
        }
      }, [])
      .catch((error) => { 
        setLogerror(true)
        setloader(false);});
  };

  return (
    <>
      <div className="heading-getstarted"></div>
      <div className="login-form">
        <form>
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="form2Example1">
             Email
            </label>
            <input
              name="email"
              type="email"
              id="email"
              className="form-control"
              value={log.email}
              onChange={(e) => handleValues(e)}
              required
            />
          </div>

          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="form2Example2">
              Password
            </label>
            <input
              name="password"
              type="password"
              id="password"
              className="form-control log"
              value={log.password}
              onChange={(e) => handleValues(e)}
              required
            />
          </div> 
          {logerror ? (
            <span className="login-error">Incorrect email or password</span>
          ) : (
            ""
          )}
          {errors ? (
            <span className="login-error">Incorrect email or password</span>
          ) : (
            ""
          )}
          <div className="row mb-4">
            <div className="col d-flex justify-content-center"></div>

            <div className="login-link">
              <p onClick={passReset}>Forgot password?</p>
            </div>
          </div>
          <button
            id="login"
            type="button"
            className="btn btn-lg-primary btn-block mb-4"
            onClick={loggedIn}
            disabled={log.email === "" || log.password === ""}
          >
            Log in
          </button>

          <div className="text-center">
            <p>Not a member? </p>
            <p className="login-link" onClick={register}>
              Register
            </p>
          </div>
        </form>
        <Loader loader={loader} />
      </div>
    </>
  );
};

export default Login;
