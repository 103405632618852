import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Blog } from "./Blog/Blog";
import { ConfirmationCheers } from "./Confirmation-Cheers/Confirmation-Cheers";
import { ConfirmationfiveStyle } from "./Confirmation-fIve-Style/Confirmation-fIve-Style";
import { ConfirmationHero } from "./Confirmation-Hero/Confirmation-Hero";
import { ConfirmationStayConnected } from "./Confirmation-Stay-Connected/Confirmation-Stay-Connected";
import Catalogs from "../Login/Catalogs/Catalogs";
import "./Confirmation.css";
import data from "../Home/GetStarted/body.json";

export const Confirmation = () => {
  let { background_id } = useParams();
  const [backImage, setBackImage] = useState();
  const [color, setColor] = useState();

  useEffect(() => {
    const findImage = data.find((x) => x.style_number === background_id);
    if (findImage) {
      setBackImage(findImage.id);
      setColor(findImage.color);
      document.documentElement.style.setProperty(
        "--theme-img",
        findImage.background
      );
    }
    window.scrollTo(0, 0);
  }, [color]);

  return (
    <div className="">
      {color && (
        <>
          <ConfirmationHero color={color} backImage={backImage} />
          <ConfirmationCheers />
          <ConfirmationfiveStyle />
          <ConfirmationStayConnected color={color} />
          <Blog />
          <div className="howitworks-container streamline-container">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="howitworks-content newsletter-content ">
                    <h2 className="font-lg-24">
                    Stay connected and join our community
                    </h2>
                    <p className="pt-2">
                    You're in good company. Our community of stylists and like-minded fashionistas, like yourself, are here to help. Join our private ShapeShopp™ Confidential Community and get tailored info sent directly to you!
                    </p>
                    <div className="font-lg-24 join">
                      {" "}
                      <u>
                        <a
                          href="https://www.facebook.com/groups/524782371339690"
                          target="blank"
                          id="Join now_facebook"
                        >
                          Join Now
                        </a>
                      </u>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <img
                  className="fb-cover-img"
                    src="../../../../images/Facebook_Cover__1640___856_px_.jpg"
                    height="300px"
                    width="100%"
                    alt=""
                  />
                </div>
              </div>
              <div className="color-bar bar_color">
                <div className="multi-color-section"></div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
