import React, { useState, useEffect } from "react";
import { FadeInSection } from "../../shared/Layout/IntersectionObserver";
import "./StepDisplay.css";
import { useNavigate } from "react-router-dom";
import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

export const StepDisplay = () => {
  const [step, setStep] = useState();
  const [section3, setSection3] = useState();
  const [started, setStarted] = useState();

  useEffect(async () => {
    const entry = await client.getEntries({
      content_type: "stepDisplay",
      order: "sys.id",
    });
    setStep(entry.items);

    const entry2 = await client.getEntries({
      content_type: "shapeshoppSection3",
      order: "sys.id",
    });
    setSection3(entry2.items);

    const entry3 = await client.getEntries({
      content_type: "shapeShoppGetStarted",
      order: "sys.id",
    });
    setStarted(entry3.items);
  }, []);

  let profileTok = localStorage.getItem("tokenn");

  const navigate = useNavigate();
  const click = () => {
    navigate("/get-started");
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="step-display-container">
        <div className="container">
          {step?.map((elements, key) => (
            <div className="container" key={key}>
              <FadeInSection>
                <div className="row align-items-center step-display-row">
                  <div className="col-md-6">
                    <img
                      src={elements.fields?.stepImage.fields.file.url}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="step-display-item-content">
                      <div>
                        <div className="step-label">
                          <p>{elements.fields?.step}</p>
                        </div>
                        <h4>{elements.fields?.shape}</h4>

                        <p className="lato-font font-lg-24">
                          {elements.fields?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </FadeInSection>
            </div>
          ))}
          <div className="container">
            <div className="no-credit-card-need">
              <div className="row">
                <div className="col-md-12">
                  <div className="get-started">
                    {section3?.map((ele, key) => (
                      <h2 key={key} className="font-lg-32">
                        {ele?.fields?.text}
                      </h2>
                    ))}
                    <div className="hero-img">
                      <img src="/images/colored_circles_for_site.png" alt="" />
                    </div>

                    {profileTok ? (
                      ""
                    ) : (
                      <>
                        {started?.map((ele, key) => (
                          <FadeInSection key={key}>
                            <button
                              key={key}
                              id="get-started landing-mid"
                              className="btn btn-lg-primary"
                              onClick={click}
                            >
                              {ele?.fields?.text}
                            </button>
                          </FadeInSection>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="banner2 mt-5"></div>
    </>
  );
};
