import React, { useEffect, useState } from "react";
import "./HeroSection.css";
import { useNavigate } from "react-router-dom";
import { createClient } from "contentful";

const client = createClient({
  environment: process.env.REACT_APP_ENVIRONMENT_NAME,
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

export const HeroSection = () => {
  const [text, setText] = useState();
  const [started, setStarted] = useState();

  useEffect(async () => {
    window.scrollTo(0, 0);

    const entry = await client.getEntries({
      content_type: "shapeshoppHero",
      order: "sys.id",
    });
    setText(entry.items);

    const entry2 = await client.getEntries({
      content_type: "shapeShoppGetStarted",
      order: "sys.id",
    });
    setStarted(entry2?.items);
  }, []);

  const navigate = useNavigate();
  const getStarted = () => {
    navigate("/get-started");
    window.scrollTo(0, 0);
  };
  let profileTok = localStorage.getItem("tokenn");

  return (
    <div className="home hero-section">
      <div className="container">
        <div className="left-col-hero mainPage">
          {text?.map((element, key) => (
            <div key={key}>
              <div className="home__hero__heading">
                <h1>
                  {element.fields.heading}
                </h1>
                <div className="mobile-view">
                  {profileTok ? (
                    ""
                  ) : (
                    <>
                      {started?.map((ele, key) => (
                        <button
                          id="get-started landing-top"
                          className="home__hero__cta btn btn-lg-primary"
                          onClick={getStarted}
                        >
                          {ele?.fields?.text}
                        </button>
                      ))}
                    </>
                  )}
                </div>
              </div>

              <p className="home__hero__sub-heading">
                {element.fields.heroText2}
                <h6 className="home__hero__hint mt-2">
                  (Patent Pending)
                </h6>
              </p>

              <div className="home__hero__circles mb-3">
                <img src="/images/colored_circles_for_site.png" alt="" />
              </div>
            </div>
          ))}

          <div className="desktop-view">
            {profileTok ? (
              ""
            ) : (
              <>
                {started?.map((ele, key) => (
                  <button
                    key={key}
                    id="get-started landing-top"
                    className="btn btn-lg-primary"
                    onClick={getStarted}
                  >
                    {ele?.fields?.text}
                  </button>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="right-col-hero"></div>
    </div>
  );
};
