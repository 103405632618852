import { useState } from "react";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";

const override = css`
  margin: 0 auto;
  color: "#000000";
  border-color: black;
  position: fixed;
  top: 50%;
  left: 50%;
`;

const Loader = ({loader}) => {
  let [color, setColor] = useState("black");
  return (
    <>
      <div className="sweet-loading">
        <BeatLoader color={color} loading={loader} css={override} size={15} />
      </div>
    </>
  );
};

export default Loader;