import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../Loader.js";

const PaymentForm = (props) => {
  const [goforward, setgoforward] = useState();
  const [loader, setloader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.plan, props.tokens, props.store, props.pay]);

  let image = props.stylenumber;

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const createPayment = async (e) => {
    setgoforward(false);
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { token } = await stripe.createToken(cardElement);

    // UPDATE CARD API---

    setloader(true);
    let tokens = null;
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/v1/users`,
        {
          user: props.payloadval,
          height_ids: props.selected== null? []:[props.selected],
          size_ids: props.size==""? [] : [props.size],
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        localStorage.setItem("tokenn", response?.data.token);
        localStorage.setItem("date", response?.data.user.created_at);
        localStorage.setItem("color", response?.data.user.body_shape_color);
        localStorage.setItem("firstTimeSignUp", true);
        tokens = response?.data?.token;
      })
      .catch((error) => {
        setloader(false);
        setgoforward(true);
      });
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/v1/users/update_card`,
        {
          token: token.id,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {}, [])
      .catch((error) => {
        setloader(false);
        setgoforward(true);
      });

    //  CREATE SUBSCRIPTION API

    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/v1/subscriptions`,
        {
          subscription: {
            stripe_customer_id: props.customerID,
            // price_id: "price_1LjmMXEO6Kb9LR1SWRJAGYHk",
            price_id: props.plan.price_id,
            promo_code: props.promoCode,
            promo_code_applied: props.promoCode == "" ? "false" : "true",
            // plan_id: "prod_ME4H1QGQGxxiRJ",
            plan_id: props.plan.planid,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${tokens}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        if (token) {
          navigate("/login-successful");
          setloader(false);
          setgoforward(true);
          localStorage.getItem("tokenn");
          localStorage.getItem("date");
          localStorage.getItem("color");
        }
      }, [])
      .catch((error) => {
        setloader(false);
        setgoforward(true);
      });
  };

  const handleChange = () => {
    goforward === true ? setgoforward(false) : setgoforward(true);
  };

  const cardStyleNumber = {
    style: {
      base: {
        color: "#575757",
        fontFamily: "poppins, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        letterSpacing: "0px",
        ":-webkit-autofill": {
          color: "#575757",
        },
        "::placeholder": {
          color: "#9C9C9C",
          fontSize: "14px",
          letterSpacing: "0.46px",
          fontWeight: "400",
          fontFamily: "poppins, sans-serif",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    showIcon: true,
    placeholder: "",
  };

  const cardStyle = {
    style: {
      base: {
        color: "#575757",
        fontFamily: "poppins, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        letterSpacing: "0px",
        ":-webkit-autofill": {
          color: "#575757",
        },
        "::placeholder": {
          color: "#9C9C9C",
          fontSize: "14px",
          letterSpacing: "0.46px",
          fontWeight: "400",
          fontFamily: "poppins, sans-serif",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    showIcon: true,
  };

  return (
    <div className="">
      <div className="row">
        <h6>Debit/Credit Card Information</h6>

        <div className="col-lg-12 mb-3">
          <h6>Card Number</h6>
          <div className="stripecard-container">
            <div className="stripe_card_number">
              <CardNumberElement
                options={cardStyleNumber}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <h6>Expiration Date</h6>
          <div className="stripe_card_expiry">
            <CardExpiryElement options={cardStyle} className="form-control" />
          </div>
        </div>
        <div className="col-lg-6">
          <h6>CVV Code</h6>
          <div className="stripe_card_cvv">
            <CardCvcElement options={cardStyle} className="form-control" />
          </div>
        </div>
        <div className="col-lg-6"></div>
      </div>
      <hr />
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          I authorize ShapeShopp to bill my card monthly.
          <p>(You may cancel your subscription at anytime)</p>
        </label>
      </div>
      <div className="action-btn">
        <button
          id="complete order"
          type="button"
          className="btn-lg-secondary-outline"
          onClick={(e) => {
            createPayment();
          }}
          disabled={!goforward}
        >
          Complete Order
        </button>
      </div>
      <Loader loader={loader} />
    </div>
  );
};

export default PaymentForm;
